import React, { useRef, useEffect, useState } from 'react';

interface Scoring {
    id: number;
    scoringSessionRequestId: number;
    scoring: 'WAKE' | 'N1' | 'N2' | 'N3' | 'REM';
    createdAt: string;
    scoringSessionRequest: null;
    request: {
        id: number;
        scoringSessionId: number;
        recommendation: string;
        strategy: string;
        segmentationId: number;
        scoringSession: null;
        sessionUserScorings: null;
    };
    segmentation: {
        id: number;
        segmentationTypeId: number;
        segmentationType: null;
        startTimestamp: string;
        stopTimestamp: string;
    };
}

interface HypnogramProps {
    scorings: Scoring[];
    brushStart: number;
    brushEnd: number;
    startTime: Date;
    durationSeconds: number;
    loadedSeconds: number;
    segmentations: any[];
    onHypnogramClick: (segmentationId: number) => void;
}

const scoringLevels = {
    "WAKE": 5,
    "N1": 3,
    "N2": 2,
    "N3": 1,
    "REM": 0,
};

const Hypnogram: React.FC<HypnogramProps> = ({
    scorings, brushStart, brushEnd, startTime, durationSeconds,
    loadedSeconds, segmentations, onHypnogramClick
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [hoveredSegmentation, setHoveredSegmentation] = useState<any | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        const padding = 10;
        const linePadding = 1.5;
        const width = canvas.clientWidth - padding * 2;
        const height = canvas.clientHeight - padding * 2;

        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;

        context.clearRect(0, 0, canvas.width, canvas.height);

        const timeToX = (timestamp: Date) => {
            const timeOffset = (new Date(timestamp).getTime() - new Date(startTime).getTime()) / 1000;
            return padding + (timeOffset / durationSeconds) * width;
        };

        const loadedX = timeToX(new Date(startTime.getTime() + loadedSeconds * 1000));

        // Draw loaded background
        context.save();
        context.fillStyle = 'rgba(0, 0, 0, 0.1)';
        context.fillRect(padding, padding, loadedX - padding, height);
        context.restore();

        

        // Draw background grid
        context.strokeStyle = '#e0e0e0';
        context.lineWidth = 0.5;
        for (let i = 0; i <= 5; i++) {
            context.beginPath();
            context.moveTo(padding, i * (height / 5) + padding);
            context.lineTo(width + padding, i * (height / 5) + padding);
            context.stroke();
        }

        // Draw the brush area
        const brushStartX = padding + (brushStart / durationSeconds) * width;
        const brushEndX = padding + (brushEnd / durationSeconds) * width;
        context.fillStyle = 'rgba(173, 216, 230, 0.3)'; // Light blue with transparency
        context.fillRect(brushStartX, padding, brushEndX - brushStartX, height);
        context.strokeStyle = 'rgba(100, 149, 237, 0.8)'; // Cornflower blue for border
        context.lineWidth = 1;
        context.strokeRect(brushStartX, padding, brushEndX - brushStartX, height);

        // Pass 1: Draw all horizontal lines
        scorings.forEach((scoring) => {
            const startX = timeToX(new Date(scoring.segmentation.startTimestamp));
            const endX = timeToX(new Date(scoring.segmentation.stopTimestamp));
            const level = scoringLevels[scoring.scoring] * (height / 5) + padding;
            const color = 'black';

            context.strokeStyle = color;
            context.lineWidth = 1;
            context.beginPath();
            context.moveTo(startX, level);
            context.lineTo(endX, level);
            context.stroke();
        });

        // Pass 2: Connect horizontal lines with vertical lines
        let prevX: number | null = null;
        let prevY: number | null = null;

        scorings.forEach((scoring) => {
            
            const endX = timeToX(new Date(scoring.segmentation.stopTimestamp));
            const level = scoringLevels[scoring.scoring] * (height / 5) + padding;

            if (prevX !== null && prevY !== null && prevY !== level) {
                context.strokeStyle = 'black';
                context.lineWidth = 1;
                context.beginPath();
                context.moveTo(prevX, prevY);
                context.lineTo(prevX, level);
                context.stroke();
            }

            prevX = endX + linePadding;
            prevY = level;
        });

        const handleClick = (event: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left - padding;
            const timeOffset = (x / width) * durationSeconds;
            const clickedTime = new Date(startTime.getTime() + timeOffset * 1000);
            

            // find the segmentation that contains the clicked time
            const clickedSeg = segmentations.find(seg => {
                const start = new Date(seg.startTimestamp).getTime();
                const end = new Date(seg.stopTimestamp).getTime();
                return clickedTime.getTime() >= start && clickedTime.getTime() <= end && clickedTime.getTime() <= (startTime.getTime() + loadedSeconds * 1000);
            });
            // call the onHypnogramClick callback with the segmentation id
            if (clickedSeg) {
                onHypnogramClick(clickedSeg.id);
            }

        };

        const handleMouseMove = (event: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left - padding;
            const timeOffset = (x / width) * durationSeconds;
            const hoveredTime = new Date(startTime.getTime() + timeOffset * 1000);

            const hoveredSeg = segmentations.find(seg => {
                const start = new Date(seg.startTimestamp).getTime();
                const end = new Date(seg.stopTimestamp).getTime();
                return hoveredTime.getTime() >= start && hoveredTime.getTime() <= end && hoveredTime.getTime() <= (startTime.getTime() + loadedSeconds * 1000);
            });

            if (hoveredSeg) {
                setHoveredSegmentation(hoveredSeg);
            } else {
                setHoveredSegmentation(null);
            }
        };

        const handleMouseLeave = () => {
            setHoveredSegmentation(null);
        };

        canvas.addEventListener('click', handleClick);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            canvas.removeEventListener('click', handleClick);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [scorings, brushStart, brushEnd, startTime, durationSeconds, loadedSeconds, segmentations, onHypnogramClick]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        const padding = 10;
        const linePadding = 1.5;
        const width = canvas.clientWidth - padding * 2;
        const height = canvas.clientHeight - padding * 2;

        const timeToX = (timestamp: Date) => {
            const timeOffset = (new Date(timestamp).getTime() - new Date(startTime).getTime()) / 1000;
            return padding + (timeOffset / durationSeconds) * width;
        };

        const redraw = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Redraw everything
            const loadedX = timeToX(new Date(startTime.getTime() + loadedSeconds * 1000));

            context.fillStyle = 'rgba(0, 0, 0, 0.1)';
            context.fillRect(padding, padding, loadedX - padding, height);


            context.strokeStyle = '#e0e0e0';
            context.lineWidth = 0.5;
            for (let i = 0; i <= 5; i++) {
                context.beginPath();
                context.moveTo(padding, i * (height / 5) + padding);
                context.lineTo(width + padding, i * (height / 5) + padding);
                context.stroke();
            }

            const brushStartX = padding + (brushStart / durationSeconds) * width;
            const brushEndX = padding + (brushEnd / durationSeconds) * width;

            context.fillStyle = 'rgba(173, 216, 230, 0.3)';
            context.fillRect(brushStartX, padding, brushEndX - brushStartX, height);
            context.strokeStyle = 'rgba(100, 149, 237, 0.8)';
            context.lineWidth = 1;
            context.strokeRect(brushStartX, padding, brushEndX - brushStartX, height);

            // Pass 1: Draw all horizontal lines
            scorings.forEach((scoring) => {
                const startX = timeToX(new Date(scoring.segmentation.startTimestamp));
                const endX = timeToX(new Date(scoring.segmentation.stopTimestamp));
                const level = scoringLevels[scoring.scoring] * (height / 5) + padding;
                const color = 'black';

                context.strokeStyle = color;
                context.lineWidth = 1;
                context.beginPath();
                context.moveTo(startX, level);
                context.lineTo(endX, level);
                context.stroke();
            });

            // Pass 2: Connect horizontal lines with vertical lines
            let prevX: number | null = null;
            let prevY: number | null = null;

            scorings.forEach((scoring) => {
                
                const endX = timeToX(new Date(scoring.segmentation.stopTimestamp));
                const level = scoringLevels[scoring.scoring] * (height / 5) + padding;

                if (prevX !== null && prevY !== null && prevY !== level) {
                    context.strokeStyle = 'black';
                    context.lineWidth = 1;
                    context.beginPath();
                    context.moveTo(prevX, prevY);
                    context.lineTo(prevX, level);
                    context.stroke();
                }

                prevX = endX + linePadding;
                prevY = level;
            });
        };

        redraw();

        if (hoveredSegmentation) {
            const startX = timeToX(new Date(hoveredSegmentation.startTimestamp));
            const endX = timeToX(new Date(hoveredSegmentation.stopTimestamp));

            // Highlight hovered segmentation
            context.save();
            context.fillStyle = 'rgba(173, 216, 230, 0.5)'; // Light blue with more transparency
            context.fillRect(startX, padding, endX - startX, height);
            context.strokeStyle = 'rgba(0, 0, 255, 0.8)'; // Dark blue for border
            context.lineWidth = 2;
            context.beginPath();
            context.moveTo(startX, padding);
            context.lineTo(startX, height + padding);
            context.stroke();
            context.beginPath();
            context.moveTo(endX, padding);
            context.lineTo(endX, height + padding);
            context.stroke();
            context.restore();
        }

    }, [hoveredSegmentation, startTime, durationSeconds, loadedSeconds, brushStart, brushEnd, scorings]);

    return (
        <canvas ref={canvasRef} style={{ width: '100%', height: '40px', border: '1px solid #ccc', display: 'block' }}></canvas>
    );
};

export default Hypnogram;
