import { SignalDataSingle } from "../utils/types";

export function processChannel(channel: any, data: any, downsampleThreshold: number): SignalDataSingle | null {
    const signalObject = data.signals.find((item: any) => item.signalDimensionName === channel.channel);
    if (!signalObject) return null;

    // let [signalValues, downsampleFactor] = downsampleSignal(signalObject.values, downsampleThreshold);

    let newSignal: SignalDataSingle = {
        signalName: channel.channel,
        values: signalObject.values,
        samplingFrequency: signalObject.samplingFrequency,
        downsampleFactor: 1,
        measurementUnit: signalObject.measurementUnit,
        segmentationId: signalObject.segmentationId,
        recordingId: signalObject.recordingId,
        signalTypeName: signalObject.signalTypeName,
    };

    // if (channel.refChannels && channel.refChannels.length) {
    //     const [refSignalAverage, refChannelNames] = processReferenceChannels(channel.refChannels, data, 1);
    //     newSignal.values = newSignal.values.map((value, i) => value - refSignalAverage[i]);
    //     newSignal.signalName += '-' + refChannelNames;
    // }

    return newSignal;
}

// function processReferenceChannels(refChannels: string[], data: any, downsampleFactor: number): [number[], string] {
//     const refSignalValues: number[][] = [];
//     let refChannelNames = '';
//     refChannels.forEach((refChannelName: string) => {
        
//         const refSignalObject = data.signals.find((item: any) => item.signalDimensionName === refChannelName);
//         if (refSignalObject) {
//             //let [refSignal] = downsampleSignal(refSignalObject.values, downsampleFactor);
//             refSignalValues.push(refSignalObject.values);
//             refChannelNames += refChannelName;
//         }
//     });
    
//     return [averageArrays(refSignalValues), refChannelNames];
// }

//// in another file...
// export function averageArrays(arrays: number[][]): number[] {
//     const length = arrays[0].length; // Assuming all arrays are of equal length
//     const sumArray = Array(length).fill(0);
  
//     arrays.forEach((arr) => {
//       arr.forEach((val, i) => {
//         sumArray[i] += val;
//       });
//     });
  
//     return sumArray.map((val) => val / arrays.length);
//   }
  

// export function applyVoltageFilter(signal: SignalDataSingle, signalTypeOptions: any): SignalDataSingle {
//     // if (signal.measurementUnit === 'V') {
//     try {
//         var nsig = applyFilters(signal, signalTypeOptions);
        
//         return nsig;
//     }
//     catch (err) {
//         console.error(err);
//     }
//     return signal;
// }