import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, Link } from '@mui/material';
import { CreateTrainingScoringSession, GetScoringSessionsForLocalUser } from '../../../api/scoringAPI';

interface ScoringSession {
    id: number;
    experimentName: string;
    start: string;
    end: string;
    createdAt: string;
    closed: boolean;
}

const TrainingSessions: React.FC = () => {
    const [existingSessions, setExistingSessions] = useState<ScoringSession[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchSessions = async () => {
        try {
            const data = await GetScoringSessionsForLocalUser();
            const filteredData = data.filter((session: ScoringSession) => session.experimentName.startsWith("TRAINING_") );
            setExistingSessions(filteredData);
        } catch (err) {
            console.error("Error fetching sessions:", err);
        }
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    const handleCreateSession = async () => {
        setIsLoading(true);
        try {
            var data = await CreateTrainingScoringSession();
            var newId = data.newId;
            // navigate to the new session
            window.location.href = `/training/${newId}`;
            // await fetchSessions(); // Ensure fetchSessions completes after creating a session
        } catch (err) {
            console.error("Error creating session:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const nonClosedSessions = existingSessions.filter(session => !session.closed);
    const closedSessions = existingSessions.filter(session => session.closed);

    nonClosedSessions.sort((a, b) => a.id - b.id);
    closedSessions.sort((a, b) => a.id - b.id);

    let hasTutorialInClosedSessions = closedSessions.some(session => session.experimentName.includes("TUTORIAL_"));
    hasTutorialInClosedSessions = true; // Remove this line after implementing the correct logic
    const canCreateSession = hasTutorialInClosedSessions && nonClosedSessions.length === 0;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <Typography variant="h4">Create training session</Typography>
            <Typography variant="h6">A training session gives you instant feedback when you score, however you only get one chance at scoring an epoch.</Typography>

            
            <Button
                variant="contained"
                color="primary"
                onClick={handleCreateSession}
                disabled={!canCreateSession || isLoading}
            >
                {isLoading ? "Creating Session..." : "Create Session"}
            </Button>
            {!hasTutorialInClosedSessions && (
                <Typography>You need to finish the tutorial before you create a session of this type.</Typography>
            )}
            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h5">Open Sessions</Typography>
                <List>
                    {nonClosedSessions.map(session => (
                        <ListItem key={session.id}>
                            <ListItemText
                                primary={
                                    <Link href={`/training/${session.id}`} underline="hover">
                                        {session.experimentName}
                                    </Link>
                                }
                                secondary={`Start: ${new Date(session.start).toLocaleString()} | End: ${new Date(session.end).toLocaleString()}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h5">Closed Sessions</Typography>
                <List>
                    {closedSessions.map(session => (
                        <ListItem key={session.id}>
                            <ListItemText
                                primary={
                                    <Link href={`/scoringsession/${session.id}/results`} underline="hover">
                                        {session.experimentName}
                                    </Link>
                                }
                                secondary={`Start: ${new Date(session.start).toLocaleString()} | End: ${new Date(session.end).toLocaleString()}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default TrainingSessions;
