import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // Using ArrowUpwardIcon as a placeholder for Shift

interface ScoringButtonsProps {
    answers: string[];
    onAnswerSelected: (answer: string) => void;
    onSkip: () => void;
    mlRecommendation: string | null; // Make this optional if not always provided
    disabled?: boolean;
    strategy: string | null;
    moveEpochLeft: () => void;
    moveEpochRight: () => void;
    nudgeLeft: () => void;
    nudgeRight: () => void;
}

const ScoringButtons: React.FC<ScoringButtonsProps> = ({
    answers,
    onAnswerSelected,
    onSkip,
    mlRecommendation,
    strategy,
    disabled = false,
    moveEpochLeft,
    moveEpochRight,
    nudgeLeft,
    nudgeRight,
}) => {
    const [hoveredKey, setHoveredKey] = useState<string | null>(null);
    if (disabled === null) { disabled = false; }

    const getRecommendationEmojiAndText = (index: number) => {
        if (strategy === "ai") {
            return { emoji: "🤖", text: "AI Recommendation" };
        } else {
            const emoji = index % 2 === 0 ? "👩‍🔬" : "👨‍🔬";
            return { emoji, text: "Human Recommendation" };
        }
    };

    const mapAnswersToKeys = () => {
        return answers.map((answer, index) => {
            const key = answer === "REM" ? "5" : `${index}`;
            const isRecommended = answer.toLowerCase() === mlRecommendation?.toLowerCase();
            const { emoji, text } = getRecommendationEmojiAndText(index);
            return {
                answer,
                key,
                isRecommended,
                recommendationEmoji: emoji,
                recommendationText: text,
            };
        });
    };

    const answersToKeys = mapAnswersToKeys();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const keyNumber = event.key;
            const button = answersToKeys.find((button) => button.key === keyNumber);
            if (button) {
                if (button.answer === "Skip") {
                    onSkip();
                } else {
                    onAnswerSelected(button.answer);
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [answersToKeys, onAnswerSelected, onSkip]);

    return (
        <div style={{ marginTop: "5px", marginBottom: "-5px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <Button 
                    onClick={moveEpochLeft} 
                    disabled={disabled} 
                    variant="outlined" 
                    size="small"
                    style={{ minWidth: 'auto', padding: '0 4px', marginRight: '5px' }}
                >
                    {/* <ArrowUpwardIcon fontSize="small" /> */}
                    <Typography fontSize={9 } variant="body1">A</Typography>
                    <KeyboardDoubleArrowLeftIcon fontSize="small" />
                </Button>
                <Button 
                    onClick={nudgeLeft} 
                    disabled={disabled} 
                    variant="outlined" 
                    size="small" 
                    style={{ minWidth: 'auto', padding: '0 4px' }}
                >
                    <Typography fontSize={9 } variant="body1">Shift + A</Typography>
                    <KeyboardArrowLeftIcon fontSize="small" />
                </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '30px' }}>
                {answersToKeys.map(({ answer, key, isRecommended, recommendationEmoji, recommendationText }) => (
                    <div key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginRight: '10px' }}>
                        {isRecommended && hoveredKey === key && (
                            <div style={{
                                position: 'absolute',
                                bottom: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                marginBottom: '5px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                padding: '2px 5px',
                                borderRadius: '14px',
                                fontSize: '10px',
                                whiteSpace: 'nowrap',
                            }}>
                                {recommendationText}
                            </div>
                        )}
                        <Button
                            disabled={disabled}
                            variant="contained"
                            color={answer === "Skip" ? "secondary" : "primary"}
                            onMouseEnter={() => setHoveredKey(key)}
                            onMouseLeave={() => setHoveredKey(null)}
                            onClick={() => answer === "Skip" ? onSkip() : onAnswerSelected(answer)}
                            sx={{
                                height: "18px",
                                width: "80px",
                                position: 'relative',
                                fontSize: '10px',
                                ...(isRecommended && { boxShadow: "0 0 0 4.5px limegreen" }),
                            }}
                        >
                            <span>{answer} ({key})</span>
                            {isRecommended && (
                                <span style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    transform: 'translate(40%, -50%)',
                                    fontSize: '16px',
                                }}>
                                    {recommendationEmoji}
                                </span>
                            )}
                        </Button>
                    </div>
                ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Button 
                    onClick={nudgeRight} 
                    disabled={disabled} 
                    variant="outlined" 
                    size="small" 
                    style={{ minWidth: 'auto', padding: '0 4px', marginRight: '5px' }}
                >
                    <KeyboardArrowRightIcon fontSize="small" />
                    
                    <Typography fontSize={9 } variant="body1">Shift + D</Typography>
                </Button>
                <Button 
                    onClick={moveEpochRight} 
                    disabled={disabled} 
                    variant="outlined" 
                    size="small"
                    style={{ minWidth: 'auto', padding: '0 4px' }}
                >
                    <KeyboardDoubleArrowRightIcon fontSize="small" />
                    <Typography fontSize={9 } variant="body1">D</Typography>
                    {/* <ArrowUpwardIcon fontSize="small" /> */}
                </Button>
            </div>
        </div>
    );
};

export default ScoringButtons;
