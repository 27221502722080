import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import {
  calculateNewOffset, drawInfoReferenceArea, drawReferenceArea,
  drawReferenceLine, drawSignal, drawVerticalTimeLines, enforceCanvasBounds, findClosestSignal,
  redrawInfoCanvas, canvasScale,
  drawNormalAnnotation,
  drawTrainingAnnotation,
  drawRecommendation
} from './utils';
import { SleepChartProps } from './types';
import { Button } from '@mui/material';

const SleepChart: React.FC<SleepChartProps> = ({
  signals, signalGroupOptions, brushStartSeconds, brushEndSeconds, originalSegmentStart,
  originalSegmentEnd, mlRecommendation, strategy, darkMode, experimentId, annotations = [], recommendations = [], trainingAnnotations = [],
  sessionStart
}) => {
  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const backgroundCanvasRef = useRef<HTMLCanvasElement>(null);
  const infoCanvasRef = useRef<HTMLCanvasElement>(null);
  const leftMargin = 100;
  const padding = 10;
  const scale = canvasScale;
  const [dragging, setDragging] = useState(false);
  const [scaling, setScaling] = useState(false);
  const [rightClickDown, setRightClickDown] = useState(false);
  const dragIndexRef = useRef<number | null>(null);
  const dragStartYRef = useRef(0);
  const initialRightClickPosRef = useRef({ x: 0, y: 0 });

  const signalLengthInSeconds = useMemo(() => {
    return signals.length > 0 ? signals[0].values.length / signals[0].samplingFrequency : 0;
  }, [signals]);

  
  const emoji_text = useMemo(() => {
    let human_emoji = ["🧑‍⚕️", "👩‍⚕️"];
    let human = human_emoji.sort(() => Math.random() - 0.5)[0];
    let text = strategy === "ai" ? "🤖" : human;

    if (strategy !== null && mlRecommendation !== null) {
      text += ` ${mlRecommendation}`;
    }
    return text;
  }, [strategy, mlRecommendation]);

  const loadInitialSignalProperties = useCallback((ignoreSaved: boolean = false) => {
    const savedProperties = localStorage.getItem(`signalProperties_session_${experimentId}`);
    if (savedProperties && !ignoreSaved) {
      return JSON.parse(savedProperties);
    }

    const rect = backgroundCanvasRef.current?.getBoundingClientRect();
    const canvasHeight = rect ? rect.height : window.innerHeight * 0.9;
    const numberOfSignals = signals.length;
    const verticalSpacing = canvasHeight / (numberOfSignals + 1);

    let props = signals.map((signal, index) => {
      const { values } = signal;
      const minVal = Math.min(...values);
      const maxVal = Math.max(...values);
      const range = maxVal - minVal;
      const initialScale = 50 / range;
      const initialOffset = verticalSpacing * (index + 1);
      return { offset: initialOffset, scale: initialScale, highlightInterestWaves: false };
    });

    const groupScales = {} as { [key: string]: number[] };
    const groupAverages = {} as { [key: string]: number };

    signals.forEach((signal, index) => {
      const group = signal.signalTypeName;
      if (!groupScales[group]) {
        groupScales[group] = [];
      }
      groupScales[group].push(props[index].scale);
    });

    Object.keys(groupScales).forEach(group => {
      if (groupScales[group] && groupScales[group].length > 0) {
        const avgScale = groupScales[group].reduce((a, b) => a + b, 0) / groupScales[group].length;
        groupAverages[group] = avgScale;
      } else {
        // Handle the case where groupScales[group] is empty or undefined
        groupAverages[group] = 1; // or some default value
      }
    });

    signals.forEach((signal, index) => {
      props[index].scale = groupAverages[signal.signalTypeName];
      if (signal.signalTypeName === "SAS_EMG") {
        props[index].scale = groupAverages[signal.signalTypeName] / 8;
      }
    });

    localStorage.setItem(`signalProperties_session_${experimentId}`, JSON.stringify(props));
    return props;

  }, [experimentId, signals]);

  const [signalProperties, setSignalProperties] = useState<{ offset: number; scale: number; highlightInterestWaves?: boolean }[]>([]);

  useEffect(() => {
    setSignalProperties(loadInitialSignalProperties());
  }, [loadInitialSignalProperties]);

  const redrawSingleSignal = useCallback((index: number, properties: { offset: number; scale: number; highlightInterestWaves?: boolean }[] = []) => {
    const canvas = canvasRefs.current[index];
    const context = canvas?.getContext('2d');
    const rect = canvas?.getBoundingClientRect();

    if (!canvas || !context || !rect) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
    drawSignal(context, signals[index], properties[index] || { offset: 0, scale: 1 }, darkMode);
    let signalGroupOption = signalGroupOptions.find(sgo => sgo.name === signals[index].signalTypeName);
    if (signalGroupOption) {
      drawReferenceLine(context, signals[index], signalGroupOption, properties[index] || { offset: 0, scale: 1 }, false, true);
    }
  }, [signals, signalGroupOptions, darkMode ]);

  const handleMouseUp = useCallback((event: React.MouseEvent) => {
    if (signalProperties.length > 0) {
      localStorage.setItem(`signalProperties_session_${experimentId}`, JSON.stringify(signalProperties));
    }
    setDragging(false);
    setScaling(false);
    dragIndexRef.current = null;
    redrawInfoCanvas(infoCanvasRef, signals, signalProperties);

    const distanceMoved = Math.sqrt(
      Math.pow(event.clientX - initialRightClickPosRef.current.x, 2) +
      Math.pow(event.clientY - initialRightClickPosRef.current.y, 2)
    );

    if (event.button === 2 && distanceMoved < 5 && rightClickDown) {
      const rect = backgroundCanvasRef.current?.getBoundingClientRect();
      const mouseX = (event.clientX - (rect?.left ?? 0) - leftMargin - padding);
      const mouseY = (event.clientY - (rect?.top ?? 0) - padding);
      if (!rect) return;
      let mouseXInSeconds = (mouseX / (rect.width - leftMargin - padding)) * signalLengthInSeconds;
      const { closestIndex } = findClosestSignal(mouseXInSeconds, mouseY, signals, signalProperties, (rect.width - leftMargin - padding), rect.height - padding);
      if (closestIndex !== null) {
        // Handle signal highlight or other actions
      }
    }
    setRightClickDown(false);
  }, [signalProperties, experimentId, signals, signalLengthInSeconds, rightClickDown]);

  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    const rect = backgroundCanvasRef.current?.getBoundingClientRect();
    const mouseX = (event.clientX - (rect?.left ?? 0) - leftMargin - padding);
    const mouseY = (event.clientY - (rect?.top ?? 0) - padding);
    if (!rect) return;

    let mouseXInSeconds = (mouseX / (rect.width - leftMargin - padding)) * signalLengthInSeconds;
    const { closestIndex } = findClosestSignal(mouseXInSeconds, mouseY, signals, signalProperties, (rect.width - leftMargin - padding), rect.height - padding);

    if (event.button === 0 && closestIndex !== null) {
      setDragging(true);
      dragIndexRef.current = closestIndex;
      dragStartYRef.current = mouseY - signalProperties[closestIndex].offset;
    } else if (event.button === 2 && closestIndex !== null) {
      setScaling(true);
      dragIndexRef.current = closestIndex;
      dragStartYRef.current = mouseX; // Use mouseX for scaling
      initialRightClickPosRef.current = { x: event.clientX, y: event.clientY };
      setRightClickDown(true);
    }
  }, [signals, signalProperties, signalLengthInSeconds]);

  const handleContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setDragging(false);
    setScaling(false);
  }, []);









  const resetSignalProperties = useCallback(() => {
    const savedProperties = loadInitialSignalProperties(true);
    setSignalProperties(savedProperties);
    localStorage.setItem(`signalProperties_session_${experimentId}`, JSON.stringify(savedProperties));
    signals.forEach((_, i) => redrawSingleSignal(i, savedProperties));
  }, [loadInitialSignalProperties, redrawSingleSignal, signals, experimentId]);

  const handleMouseMove = useCallback((event: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = backgroundCanvasRef.current?.getBoundingClientRect();
    
    

    if (!rect) return;
    if (!dragging && !scaling) return;
    const mouseY = event.clientY - rect.top - padding;
    const mouseX = event.clientX - rect.left - padding;

    if (dragging && dragIndexRef.current !== null) {
      requestAnimationFrame(() => {
        const newY = enforceCanvasBounds(mouseY - dragStartYRef.current, rect.height - padding);
        setSignalProperties((currentProperties) => {
          const updatedProperties = [...currentProperties];
          updatedProperties[dragIndexRef.current!] = { ...updatedProperties[dragIndexRef.current!], offset: newY };
          return updatedProperties;
        });

        redrawSingleSignal(dragIndexRef.current!, signalProperties);
      });
    } else if (scaling && dragIndexRef.current !== null) {
      requestAnimationFrame(() => {
        const distanceMoved = mouseX - dragStartYRef.current; // Use mouseX for scaling
        const originalScale = signalProperties[dragIndexRef.current!]?.scale ?? 1;
        const scalingFactor = 0.005;
        const newScale = originalScale * (1 + scalingFactor * distanceMoved);
        const newYOffset = calculateNewOffset(
          signals[dragIndexRef.current!]?.values,
          rect.height - padding,
          originalScale,
          newScale,
          signalProperties[dragIndexRef.current!]?.offset ?? 0,
          mouseY
        );

        setSignalProperties((currentProperties) => {
          const updatedProperties = [...currentProperties];
          updatedProperties[dragIndexRef.current!] = { ...updatedProperties[dragIndexRef.current!], scale: newScale, offset: newYOffset };
          return updatedProperties;
        });

        dragStartYRef.current = mouseX; // Update drag start to current X position

        redrawSingleSignal(dragIndexRef.current!, signalProperties);
      });
    }

  }, [dragging, scaling, signalProperties, signals, redrawSingleSignal]);

  const drawBackground = useCallback((context: CanvasRenderingContext2D, width: number, height: number) => {
    context.clearRect(0, 0, width, height);


    if (brushStartSeconds !== undefined && brushEndSeconds !== undefined && originalSegmentStart !== undefined && originalSegmentEnd !== undefined) {
      drawVerticalTimeLines(context, signalLengthInSeconds, width / signalLengthInSeconds, Math.round(brushStartSeconds - originalSegmentStart), null, darkMode, sessionStart);
    }

    if (originalSegmentStart !== 0 && originalSegmentStart !== undefined && brushStartSeconds !== undefined) {
      let startOffset = originalSegmentStart - brushStartSeconds;
      let originalSegmentStartInCanvasSpace = startOffset * (width / signalLengthInSeconds);
      drawReferenceArea(context, 0, originalSegmentStartInCanvasSpace);
    }

    if (originalSegmentEnd !== undefined && brushEndSeconds !== undefined && brushStartSeconds !== undefined) {
      let endOffset = originalSegmentEnd - brushStartSeconds;
      let originalSegmentEndInCanvasSpace = endOffset * (width / signalLengthInSeconds);
      drawReferenceArea(context, originalSegmentEndInCanvasSpace, width * 2);
    }

    if (originalSegmentStart !== undefined && originalSegmentEnd !== undefined && brushStartSeconds !== undefined && brushEndSeconds !== undefined) {
      let originalSegmentInView = brushStartSeconds < originalSegmentEnd && brushEndSeconds > originalSegmentStart;
      if (originalSegmentInView) {
        let startOffset = originalSegmentStart - brushStartSeconds;
        let originalSegmentStartInCanvasSpace = startOffset * (width / signalLengthInSeconds);
        let endOffset = originalSegmentEnd - brushStartSeconds;
        let originalSegmentEndInCanvasSpace = endOffset * (width / signalLengthInSeconds);
        let centreLocation = (originalSegmentStartInCanvasSpace + originalSegmentEndInCanvasSpace) / 2;
        if (originalSegmentStartInCanvasSpace < 0) {
          originalSegmentStartInCanvasSpace = 0;
        }
        let originalSignalVisible = originalSegmentStartInCanvasSpace < width && originalSegmentEndInCanvasSpace > 0;
        let hasRecommendation = mlRecommendation !== null && strategy !== null;
        if (originalSignalVisible && hasRecommendation) {
          drawInfoReferenceArea(context, originalSegmentStartInCanvasSpace, originalSegmentEndInCanvasSpace, emoji_text, centreLocation, darkMode);
        }
      }
    }

  }, [brushStartSeconds, brushEndSeconds, originalSegmentStart, originalSegmentEnd, signalLengthInSeconds, darkMode, mlRecommendation, strategy, emoji_text, sessionStart]);

  const resizeCanvas = useCallback(() => {
    if (signalLengthInSeconds === 0) return;

    let signalProperties = loadInitialSignalProperties();

    const canvasWidth = window.innerWidth - leftMargin - 2 * padding;
    const canvasHeight = window.innerHeight * 0.9 - 2 * padding;
    if (!backgroundCanvasRef.current || !infoCanvasRef.current) return;

    backgroundCanvasRef.current.width = canvasWidth * scale;
    backgroundCanvasRef.current.height = canvasHeight * scale;
    infoCanvasRef.current.width = leftMargin * scale;
    infoCanvasRef.current.height = canvasHeight * scale;

    backgroundCanvasRef.current.style.width = `${canvasWidth}px`;
    backgroundCanvasRef.current.style.height = `${canvasHeight}px`;
    infoCanvasRef.current.style.width = `${leftMargin}px`;
    infoCanvasRef.current.style.height = `${canvasHeight}px`;

    const backgroundContext = backgroundCanvasRef.current.getContext('2d');
    const infoContext = infoCanvasRef.current.getContext('2d');

    if (!backgroundContext || !infoContext) return;

    backgroundContext.setTransform(scale, 0, 0, scale, 0, 0);
    infoContext.setTransform(scale, 0, 0, scale, 0, 0);

    backgroundContext.clearRect(0, 0, backgroundCanvasRef.current.width, backgroundCanvasRef.current.height);
    infoContext.clearRect(0, 0, infoCanvasRef.current.width, infoCanvasRef.current.height);

    infoContext.fillStyle = '#f8f8f8';
    infoContext.fillRect(0, 0, infoCanvasRef.current.width, infoCanvasRef.current.height);

    drawBackground(backgroundContext, canvasWidth, canvasHeight);

    let g = 5;
    const gradient = backgroundContext.createLinearGradient(0, 0, g, 0);
    let w = 200;
    gradient.addColorStop(0, `rgba(${w}, ${w}, ${w}, 1)`);
    gradient.addColorStop(1, `rgba(${w}, ${w}, ${w}, 0)`);
    backgroundContext.fillStyle = gradient;
    backgroundContext.fillRect(0, 0, g, canvasHeight);

    const updateCanvasDimensions = (canvas: HTMLCanvasElement, width: number, height: number, scale: number) => {
      const scaledWidth = width * scale;
      const scaledHeight = height * scale;

      if (canvas.width !== scaledWidth || canvas.height !== scaledHeight) {
        canvas.width = scaledWidth;
        canvas.height = scaledHeight;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
      }
    };

    const prepareContext = (context: CanvasRenderingContext2D) => {
      context.setTransform(scale, 0, 0, scale, 0, 0);
    };

    redrawInfoCanvas(infoCanvasRef, signals, signalProperties);

    annotations.forEach(annotation => { drawNormalAnnotation(annotation, backgroundCanvasRef, infoCanvasRef, signalLengthInSeconds, brushStartSeconds, brushEndSeconds, padding) });

    trainingAnnotations.forEach(annotation => { drawTrainingAnnotation(annotation, backgroundCanvasRef, infoCanvasRef, signalLengthInSeconds, brushStartSeconds, brushEndSeconds, padding) });

    recommendations.forEach(recommendation => { drawRecommendation(recommendation, backgroundCanvasRef, infoCanvasRef, signalLengthInSeconds, brushStartSeconds, brushEndSeconds, padding) });

    signals.forEach((signal, i) => {
      if( dragging || scaling ) return;
      const signalCanvas = canvasRefs.current[i];
      if (signalCanvas) {
        const signalContext = signalCanvas.getContext('2d');
        if (signalContext) {
          updateCanvasDimensions(signalCanvas, canvasWidth, canvasHeight, scale);
          prepareContext(signalContext);
          signalContext.clearRect(0, 0, signalCanvas.width, signalCanvas.height);
          
          drawSignal(signalContext, signal, signalProperties[i] || { offset: 0, scale: 1 }, darkMode);
          const signalGroupOption = signalGroupOptions.find(sgo => sgo.name === signal.signalTypeName);
          if (signalGroupOption) {
            drawReferenceLine(signalContext, signal, signalGroupOption, signalProperties[i] || { offset: 0, scale: 1 }, false, true);
          }
        }
      }
    });
  }, [signals, signalLengthInSeconds, darkMode, drawBackground, signalGroupOptions, loadInitialSignalProperties, annotations, brushEndSeconds, brushStartSeconds, recommendations, scale, trainingAnnotations, dragging, scaling]);

  useEffect(() => {
    resizeCanvas();
  }, [resizeCanvas]);

  if (signals.length === 0) {
    return <div>No signals available</div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '0px' }}>
      <div style={{ position: 'relative', padding: `${padding}px`, paddingBottom: '0px', width: '100%', height: '85vh' }}>
        <canvas
          ref={infoCanvasRef}
          style={{ position: 'absolute', top: 0, left: 0, width: `${leftMargin}px`, height: '100%', border: '1px solid black', borderRightWidth: '0' }}
        />
        <canvas
          ref={backgroundCanvasRef}
          style={{ position: 'absolute', top: 0, left: `${leftMargin}px`, width: `calc(100% - ${leftMargin}px)`, height: '100%', border: '1px solid black', borderLeftWidth: '0', zIndex: 2 }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onContextMenu={handleContextMenu}
        />
        {signals.map((_, i) => (
          <canvas
            key={i}
            ref={el => canvasRefs.current[i] = el}
            style={{ position: 'absolute', top: 0, left: `${leftMargin}px`, width: `calc(100% - ${leftMargin}px)`, height: '100%', zIndex: 1, pointerEvents: 'none' }}
          />
        ))}
      </div>
      <Button size='large' variant='outlined' onClick={resetSignalProperties}
        sx={{
          height: "18px",
          width: "80px",
          position: 'absolute',
          top: '15px',
          right: '10px',
          fontSize: '10px',
        }}>Reset</Button>
    </div>
  );
};

export default SleepChart;
