import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const CookieDisclosure: React.FC = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const cookieAccepted = localStorage.getItem('cookieAccepted');

        if (!cookieAccepted) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieAccepted', 'true');
        setOpen(false);
    };

    const handleClose = () => {
        // This function is intentionally left empty to prevent closing the modal without accepting
    };

    return (
        <Modal
            open={open}
            onClose={handleClose} // Prevent closing by passing an empty function
            aria-labelledby="cookie-disclosure-title"
            aria-describedby="cookie-disclosure-description"
            disableEscapeKeyDown
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="cookie-disclosure-title" variant="h6" component="h2">
                    Cookie Disclosure
                </Typography>
                <Typography id="cookie-disclosure-description" sx={{ mt: 2 }}>
                    We use cookies to store your preferences and for Google Analytics to observe user traffic.
                    We do not use cookies for purposes other than purely functional and analytical.
                    By continuing to use our site, you accept minimal functional cookie usage.
                </Typography>
                <Box textAlign="right" mt={3}>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        Accept Minimal Functional Cookie Usage
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CookieDisclosure;
