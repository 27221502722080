import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
    stage: string;
}

const PriorScoringSuggestor: React.FC<Props> = ({ stage }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 10,
                left: 5,
                display: 'flex',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 60,
                    height: 60,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 25,
                        height: 25,
                        backgroundColor: 'primary.main',
                        borderRadius: '0% 50% 50% 50%',
                        transform: 'rotate(-45deg)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: `2px solid ${theme.palette.primary.dark}`,
                        boxShadow: `0 4px 8px ${theme.palette.grey[500]}`,
                        transition: 'box-shadow 0.3s ease-out',
                        animation: 'pulse 1.5s infinite',
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'rotate(-45deg) scale(1)',
                                boxShadow: `0 4px 8px ${theme.palette.primary.main}`,
                            },
                            '50%': {
                                transform: 'rotate(-45deg) scale(1.05)',
                                boxShadow: `0 6px 12px ${theme.palette.primary.main}`,
                            },
                            '100%': {
                                transform: 'rotate(-45deg) scale(1)',
                                boxShadow: `0 4px 8px ${theme.palette.primary.main}`,
                            },
                        },
                        '&:hover': {
                            boxShadow: `0 6px 12px ${theme.palette.grey[700]}`,
                        },
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            color: theme.palette.common.white,
                            transform: 'rotate(45deg)',
                        }}
                    >
                        {stage}
                    </Typography>
                </Box>
                
            </Box>
        </Box>
    );
};

export default PriorScoringSuggestor;
