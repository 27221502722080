import { ScoringAnnotation } from "./types";

export function PrepareRecommendations(request: any, segmentations: any[], annotations: any[], sessionStart: Date | null) : ScoringAnnotation {
    const segmentation = segmentations.find((seg: any) => seg.id === request.segmentationId);
    let start = new Date(segmentation.startTimestamp).getTime() - sessionStart!.getTime();
    let end = new Date(segmentation.stopTimestamp).getTime() - sessionStart!.getTime();
    start = start / 1000;
    end = end / 1000;
    const duration = end - start;
    const requiredoverlapseconds = 29.9;
    let sideline = false;
    for (const annotation of annotations) {
        // calculate overlap of annotation and recommendation
        let startMax = Math.max(start, annotation.start);
        let endMin = Math.min(start + duration, annotation.start + annotation.duration);
        let overlapSeconds = Math.max(0, endMin - startMax);
        if (overlapSeconds >= requiredoverlapseconds) {
            sideline = true;
        }
    }
    return { start, duration, annotation: request.recommendation, sideline };
}