import React, { useState, useEffect, useCallback } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import ScoringButtons from "../ScoringButtons";
import { AddScoringToSession, GetScoringSessionById } from "../../../api/scoringAPI";
import { useParams } from "react-router-dom";
import styles from "../Scoring.module.css";
import Keybindings from "../Keybinds";
import SleepChart from "./SleepChart";
import { closeSession } from "../../../api/scoringAPI";
import Hypnogram from "./Hypnogram";
import { PrepareRecommendations } from "./shared";
import {PrepareScorings} from "./apiCalls";
import { findRequestForScoring, moveBrushToLeftSegment, moveBrushToRightSegment } from "./utils";
import { LoadNext } from "./utils";
import PriorScoringSuggestor from "./PriorScoringSuggestor";


interface ScoringProps {
    darkMode: boolean;
    userId: number;
}

const ScoringSession: React.FC<ScoringProps> = ({ darkMode, userId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { scoringSessionId = "" } = useParams<{ scoringSessionId: string }>();
    const scoringSessionInt = parseInt(scoringSessionId);

    const [answers, setAnswers] = useState<string[]>([]);
    
    const [signalNames, setSignalNames] = useState<string[]>([]);
    const [brushStartSeconds, setBrushStartSeconds] = useState<number>(0.0);
    const [brushEndSeconds, setBrushEndSeconds] = useState<number>(30.0);
    
    
    const [segmentations, setSegmentations] = useState<any[]>([]);
    const [requests, setRequests] = useState<any[]>([]);
    const [sessionStart, setSessionStart] = useState<Date | null>(null);
    const [sessionEnd, setSessionEnd] = useState<Date | null>(null);
    const [signalGroupOptions, setSignalGroupOptions] = useState<any>([]);
    const [loadedDurationSeconds, setLoadedDurationSeconds] = useState<number>(0);
    const [sessionLengthInSeconds, setSessionLengthInSeconds] = useState<number>(0);
    const [signals, setSignals] = useState<any[]>([]);
    const [userScorings, setUserScorings] = useState<any[]>([]);
    const [panZoom, setPanZoom] = useState(false);

    const [priorScoring, setPriorScoring] = useState<string | null>(null);

    const [loadingNextSegment, setLoadingNextSegment] = useState(false);

    const [experimentId, setExperimentId] = useState<number>(0);

    const handleCloseSession = async () => {
        await closeSession(scoringSessionInt);
        window.location.href = "/?tab=2";
    };

    const fetchAndSetData = useCallback(async () => {
        const data = await GetScoringSessionById(scoringSessionInt);
        if (data == null) {
            setError(new Error("No signals available"));
            setIsLoading(false);
            return;
        }

        
        const sessionLength = (new Date(data.end).getTime() - new Date(data.start).getTime()) / 1000;
        setSessionLengthInSeconds(sessionLength);
        setSignals(data.signals);
        setSignalGroupOptions(data.signalTypeOptions);
        setAnswers(data.answers);
        setSignalNames(data.signals.map((signal: any) => signal.signalName));
        setExperimentId(data.experimentId);
        setPriorScoring(data.priorStage);
        
        const first = data.signals[0];
        if (!first || !first.values || first.values.length === 0) {
            setError(new Error("No signals available"));
            setIsLoading(false);
            return;
        }

        // console.log(data.start, data.end, 'session length', sessionLength);
        
        // console.log('seggos', data.segmentations);
        // console.log('reqs', data.requests);
        
        setSegmentations(data.segmentations);
        setRequests(data.requests);
        setSessionStart(new Date(data.start));
        setSessionEnd(new Date(data.end));
        setLoadedDurationSeconds(first.values.length / first.samplingFrequency);
        

        setIsLoading(false);
    }, [scoringSessionInt]);

    useEffect(() => {
        fetchAndSetData();
    }, [scoringSessionInt, fetchAndSetData]);

    const fetchAndSetScorings = useCallback(async () => {

        if (requests.length === 0 || segmentations.length === 0) {
            return;
        }
        const data = await PrepareScorings(scoringSessionInt, requests, segmentations);
    
        setUserScorings(data);
    }, [requests, scoringSessionInt, segmentations]);

    useEffect(() => {
        fetchAndSetScorings();
    }, [fetchAndSetScorings]);

    
    const handleShiftA = useCallback(() => {
        if (brushStartSeconds <= 0.5) {
            console.log("Can't go further");
        } else {
            setBrushStartSeconds(prevBrushStart => prevBrushStart - 5);
            setBrushEndSeconds(prevBrushEnd => prevBrushEnd - 5);
        }
        
    }, [brushStartSeconds]);

    const handleShiftD = useCallback(() => {
        if (brushEndSeconds >= loadedDurationSeconds - 0.4) {
            console.log("Can't go further");
        } else {
            setBrushStartSeconds(prevBrushStart => prevBrushStart + 5);
            setBrushEndSeconds(prevBrushEnd => prevBrushEnd + 5);
        }

    }, [brushEndSeconds, loadedDurationSeconds  ]);

    const handleA = useCallback(() => {
        if (scoringSessionId === "") return;

        if (brushStartSeconds <= 0.5) {
            setBrushStartSeconds(0);
            setBrushEndSeconds(30.0);
        } else {
            moveBrushToLeftSegment( brushStartSeconds, brushEndSeconds, setBrushStartSeconds, setBrushEndSeconds, loadedDurationSeconds);
        }
    }, [brushStartSeconds, brushEndSeconds, loadedDurationSeconds, scoringSessionId]);

    const handleD = useCallback(() => {
        if (scoringSessionId === "") return;

        if (brushEndSeconds >= loadedDurationSeconds - 0.5) {
            console.log("Reached the end of the loaded data");
        } else {
            moveBrushToRightSegment( brushStartSeconds, brushEndSeconds, setBrushStartSeconds, setBrushEndSeconds, loadedDurationSeconds);
        }        
    }, [brushEndSeconds, loadedDurationSeconds, brushStartSeconds, scoringSessionId]);










    const handleSpace = useCallback(() => {
        setBrushStartSeconds((prevBrushStartSeconds) => {
            const middle = (brushEndSeconds + prevBrushStartSeconds) / 2;
            let newBrushStart, newBrushEnd;

            if (panZoom) {
                newBrushStart = Math.max(15, Math.min(loadedDurationSeconds - 15, middle)) - 15;
                newBrushEnd = newBrushStart + 30;
            } else {
                newBrushStart = Math.max(45, Math.min(loadedDurationSeconds - 45, middle)) - 45;
                newBrushEnd = newBrushStart + 90;
            }

            setBrushEndSeconds(newBrushEnd);
            return newBrushStart;
        });
        setPanZoom(!panZoom);
    }, [brushEndSeconds, loadedDurationSeconds, panZoom]);

    useEffect(() => {
        const keydownHandler = (event: any) => {
            if ((event.key === 'A' || event.key === 'a') && event.shiftKey) {
                handleShiftA();
            }
            if ((event.key === 'a' && !event.shiftKey) || (event.key === 'ArrowLeft' && !event.shiftKey)) {
                handleA();
            }
            if ((event.key === 'D' || event.key === 'd' || event.key === "ArrowRight") && event.shiftKey) {
                handleShiftD();
            }
            if ((event.key === 'd' && !event.shiftKey) || (event.key === 'ArrowRight' && !event.shiftKey)) {
                handleD();
            }
            if (event.key === ' ') {
                event.preventDefault();
                handleSpace();
            }
        };

        window.addEventListener('keydown', keydownHandler);

        return () => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, [handleShiftA, handleShiftD, handleA, handleD, handleSpace]);

    const handleAnswerSelected = async (answer: string) => {
        if (scoringSessionId === "") {
            return;
        }
        if (brushEndSeconds - brushStartSeconds > 30) {
            return;
        }
        if(sessionStart === null) {
            return;
        }
        
        let [segmentation, request] = findRequestForScoring(brushStartSeconds, brushEndSeconds,  requests, segmentations, sessionStart);

        if(segmentation === null || request === null) {
            throw new Error("No segmentation or request found");
        }

        await AddScoringToSession(scoringSessionInt, answer, request.id);
        const userScoring = {
            scoring: answer,
            segmentation: segmentation,
            request: request
        };
        const newScorings = userScorings.filter((scoring: any) => scoring.segmentation.id !== segmentation.id);
        setUserScorings([...newScorings, userScoring]);
        // if possible, advance the brush to the next segment
        // console.log("Going to next segment");
        
        if (brushEndSeconds + 30 <= loadedDurationSeconds) {
            // find the next segment
            // console.log("Going to next segment");
        
            if (brushEndSeconds + 30 <= loadedDurationSeconds) {
                // find the next segment
                const nextSegmentation = segmentations.find(seg => seg.startTimestamp === segmentation.stopTimestamp);
                if (nextSegmentation) {
                    const start = new Date(nextSegmentation.startTimestamp).getTime() - sessionStart!.getTime();
                    const end = new Date(nextSegmentation.stopTimestamp).getTime() - sessionStart!.getTime();
                    setBrushStartSeconds(start / 1000);
                    setBrushEndSeconds(end / 1000);
                } else {
                    console.log("Did not find next segment");
                }
            } else {
                console.log("No more segments to score");   
            }

        } else {
            console.log("No more segments to score");   
        }

    };



    const isNotNull = (annotation: any): annotation is any => annotation !== null;

    const annotations: any[] = userScorings.map((scoring: any) => {
        if (scoring.segmentation === undefined) {
            return null;
        }
        let start = new Date(scoring.segmentation.startTimestamp).getTime() - sessionStart!.getTime();
        let end = new Date(scoring.segmentation.stopTimestamp).getTime() - sessionStart!.getTime();
        start = start / 1000;
        end = end / 1000;
        const duration = end - start;
        return { start, duration, annotation: scoring.scoring };
    }).filter(isNotNull);


    const recommendations = requests.filter((request: any) => request.recommendation !== "").map((r:any) => { return PrepareRecommendations(r, segmentations, annotations, sessionStart) });

    const loadNextSegmentation = useCallback(async () => {
        if (loadingNextSegment || loadedDurationSeconds >= sessionLengthInSeconds) {
            return;
        }
        setLoadingNextSegment(true);
        try {
    
            const [updatedSignals, newDuration] = await LoadNext(segmentations, signals, loadedDurationSeconds, sessionStart, scoringSessionInt);
            
            if(updatedSignals === null || newDuration === null) {
                throw new Error("Error loading next segment");
            }
            setSignals(updatedSignals);
            setLoadedDurationSeconds(newDuration);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingNextSegment(false);
        }
    }, [loadingNextSegment, loadedDurationSeconds, segmentations, sessionLengthInSeconds, signals, scoringSessionInt, sessionStart]);

    useEffect(() => {
        if (!loadingNextSegment && loadedDurationSeconds < sessionLengthInSeconds) {
            loadNextSegmentation();
        }
    }, [loadedDurationSeconds, loadNextSegmentation, loadingNextSegment, sessionLengthInSeconds]);

    
        

    if (isLoading) {
        return (
            <Container component="main" maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" pt={8} >
                    <div className={styles.loader}></div>
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container component="main" maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" pt={8} >
                    <Typography component="h1" variant="h2">
                        {"???"}
                    </Typography>
                    <Typography component="h1" variant="h3" pt={8}>
                        Error: {error.message}
                    </Typography>
                </Box>
            </Container>
        );
    }

    // calculate whether or not to show the "close session" button
    // if the length of the user scorings is equal to the length of the segmentations (only type 1)
    const showCloseButton = userScorings.length === segmentations.filter((seg: any) => seg.segmentationTypeId === 1).length;

    if (signalNames.length === 0) {
        return (
            <Container component="main" maxWidth="xl" className="scoring-container" >
                <Box display="flex" flexDirection="column" alignItems="center" pt={8} >
                    
                    <Typography component="h1" variant="h3" pt={8}>
                        No data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    const trimmedSignals = signals.map((signal: any) => {
        const startIdx = Math.max(0, Math.floor(brushStartSeconds * signal.samplingFrequency));
        const endIdx = Math.min(signal.values.length, Math.floor(brushEndSeconds * signal.samplingFrequency));
        const values = signal.values.slice(startIdx, endIdx);
        return { ...signal, values };
    });

    const scoringSessionDuration = (sessionEnd!.getTime() - sessionStart!.getTime()) / 1000;

    const handleZoomToSegmentation = (segmentationId: number) => {
        const segmentation = segmentations.find(seg => seg.id === segmentationId);
        if (!segmentation) return;

        const start = new Date(segmentation.startTimestamp).getTime() - sessionStart!.getTime();
        const end = new Date(segmentation.stopTimestamp).getTime() - sessionStart!.getTime();
        setBrushStartSeconds(start / 1000);
        setBrushEndSeconds(end / 1000);
    };

    // only show the prior scoring if it is not null, and the user is viewing the first segment (with 0.1 sec buffer)
    let showPriorScoring = (priorScoring !== null && brushStartSeconds < 0.1 && brushEndSeconds < 30.1);

    return (
        <>
            {showPriorScoring && <PriorScoringSuggestor stage={priorScoring as string} /> }
            <Keybindings />
            <ScoringButtons
                answers={answers}
                onAnswerSelected={handleAnswerSelected}
                onSkip={() => window.location.reload()}
                mlRecommendation={null}
                strategy={null}
                moveEpochLeft={handleShiftA}
                moveEpochRight={handleShiftD}
                nudgeLeft={handleA}
                nudgeRight={handleD}
            />

            
            <Box display="flex" flexDirection="row" alignItems="center">
                {sessionStart && (
                    <Box sx={{ flexBasis: showCloseButton ? "80%" : "100%", transition: "flex-basis 0.3s" }}>
                        <Hypnogram brushEnd={brushEndSeconds} brushStart={brushStartSeconds} startTime={sessionStart} durationSeconds={scoringSessionDuration} scorings={userScorings} loadedSeconds={loadedDurationSeconds} segmentations={segmentations} onHypnogramClick={handleZoomToSegmentation} />
                    </Box>
                )}
                {showCloseButton && (
                    <Box flexBasis="20%" display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCloseSession}
                            style={{ margin: "2px", height: "100%" }}
                        >
                            Close session
                        </Button>
                    </Box>
                )}
            </Box>

            <SleepChart experimentId={experimentId} signals={trimmedSignals}
                signalGroupOptions={signalGroupOptions} brushStartSeconds={brushStartSeconds}
                brushEndSeconds={brushEndSeconds} originalSegmentStart={0}
                originalSegmentEnd={Infinity} mlRecommendation={null}
                strategy={null} annotations={annotations}
                recommendations={recommendations} darkMode
                sessionStart={sessionStart} />
        </>
    );
};

export default ScoringSession;
