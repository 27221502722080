import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, Link, Stepper, StepLabel, Step, CircularProgress, Chip  } from '@mui/material';
import { CreateAlternatingScoringSession, GetScoringSessionsForLocalUser } from '../../../api/scoringAPI';
import PersonIcon from '@mui/icons-material/Person';
import { getUserIdentity } from '../../../api/authAPI';


interface ScoringSession {
    id: number;
    experimentName: string;
    start: string;
    end: string;
    createdAt: string;
    closed: boolean;
}

const ExperimentalSetupSessions: React.FC = () => {
    const [existingSessions, setExistingSessions] = useState<ScoringSession[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    interface Identity {
        email: string;
        // Add other properties if needed
    }
    
    const [identity, setIdentity] = useState<Identity | null>(null);


    useEffect(() => {
        getUserIdentity().then(data => {
            setIdentity(data);
        }).catch(err => {
            console.log("I am going to navigate to / in the experimentalsessions.tsx file");
            window.location.href = "/";
        });
    }, [setIdentity]);

    const fetchSessions = async () => {
        try {
            const data = await GetScoringSessionsForLocalUser();
            const filteredData = data.filter((session: ScoringSession) =>
                session.experimentName.startsWith("DOUBLESETUP_") || session.experimentName.startsWith("TUTORIAL_")
            );
            setExistingSessions(filteredData);
        } catch (err) {
            console.error("Error fetching sessions:", err);
        }
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    const handleCreateSession = async () => {
        setIsLoading(true);
        try {
            const data = await CreateAlternatingScoringSession();
            const newId = data.newId;
            window.location.href = `/scoringsession/${newId}`;
        } catch (err) {
            console.error("Error creating session:", err);
        } finally {
            setIsLoading(false);
        }
    };

    // sort sessions by id
    existingSessions.sort((a, b) => a.id - b.id);

    const nonClosedSessions = existingSessions.filter(session => !session.closed);
    const closedSessions = existingSessions.filter(session => session.closed);

    const hasTutorialInClosedSessions = closedSessions.some(session => session.experimentName.includes("TUTORIAL_"));
    const doublesetupClosedSessions = closedSessions.filter(session => session.experimentName.includes("DOUBLESETUP_"));
    
    let doublesetupOpenSessions = nonClosedSessions.filter(session => session.experimentName.includes("DOUBLESETUP_"));
    let canCreateSession = hasTutorialInClosedSessions && doublesetupOpenSessions.length === 0;
    

    // calculate the number of days since the last closed session
    const lastClosedSession = doublesetupClosedSessions[doublesetupClosedSessions.length - 1];
    const daysSinceLastClosedSession = lastClosedSession ? Math.round((Date.now() - new Date(lastClosedSession.createdAt).getTime()) / 1000 / 60 / 60 / 24) : -1;

    let activeStep = doublesetupClosedSessions.length;

    let waitingperiod = 1; 
    
    if (doublesetupClosedSessions.length === 0) {
        activeStep = 0;
    } else if (doublesetupClosedSessions.length === 1) {
        activeStep = 1;
    } else if (doublesetupClosedSessions.length === 2 && daysSinceLastClosedSession <= waitingperiod) {
        activeStep = 2;
    } else if (doublesetupClosedSessions.length === 2 && daysSinceLastClosedSession > waitingperiod) {
        activeStep = 3;
    }
    else if (doublesetupClosedSessions.length === 3) {
        activeStep = 4;
    } else if (doublesetupClosedSessions.length === 4) {
        activeStep = 5;
    }


    console.log(identity);
    let fmtURI = `https://docs.google.com/forms/d/e/1FAIpQLSezPu0FlK5VQr-6Yzu732Ttap8I37MqvptQjwUWc2qc-ORp5w/viewform?usp=pp_url&entry.283338786=${identity?.email}`
    let normalUri = 'https://docs.google.com/forms/d/e/1FAIpQLSezPu0FlK5VQr-6Yzu732Ttap8I37MqvptQjwUWc2qc-ORp5w/viewform?usp=sf_link';
    let surveyUrl = identity ? fmtURI : normalUri;
    
    const stepLabels = [
        "First Scoring Session",
        "Second Scoring Session",
        "One Week Break",
        "Third Scoring Session",
        "Fourth Scoring Session"
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, px: 2 }}>
            <Typography variant="h4" gutterBottom>
                ScoreCraft Study.
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
                This is a sleep stage scoring study, where you will score sleep stages on two different recording types, following the American Academy of Sleep Medicine (AASM) version 3 guidelines for sleep stage scoring.
            </Typography>
            <Typography variant="body1" fontWeight={10001} align="center" gutterBottom>
                Please read the <Link href="/instructions">instructions</Link> carefully before creating and scoring sessions.
            </Typography>

            <Stepper alternativeLabel activeStep={activeStep} sx={{ width: '100%', mt: 4 }}>
                {stepLabels.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            optional={index === activeStep ? <PersonIcon /> : null}
                            >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeStep === 2 && (
                <Typography align="center" sx={{ mt: 2 }}>
                    You have scored {doublesetupClosedSessions.length} sessions. Please wait for one week before scoring the next session. You created your last session {daysSinceLastClosedSession} days ago.
                </Typography>
            )}  

            <Box sx={{ mt: 4, width: '100%', textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateSession}
                    disabled={!canCreateSession || isLoading}
                    sx={{ minWidth: 200 }}
                >
                    {isLoading ? <CircularProgress size={24} /> : "Create Session"}
                </Button>
            </Box>

            {!hasTutorialInClosedSessions && (
                <Chip style={{marginTop:'15px'}} color='info' label="You need to finish the tutorial before you can create a session of this type." />
            )}
            {/* Link to survey that appears when the user has scored four sessions */}
            {doublesetupClosedSessions.length >= 4 && (
                <Typography align="center" sx={{ mt: 2 }}>
                    {/* make the link open a new tab */}
                    You have scored four sessions. Please fill out the 
                    <Link href={surveyUrl} target="_blank" rel="noopener noreferrer"> feedback survey</Link>.
                </Typography>
            )}

            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h5" gutterBottom>
                    Open Sessions
                </Typography>
                <List>
                    {doublesetupOpenSessions.map(session => (
                        <ListItem key={session.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                            <ListItemText
                                primary={
                                    <Link href={`/scoringsession/${session.id}`} underline="hover" color="primary">
                                        {session.experimentName.replace("DOUBLESETUP_", "").replace("SAS", "Self-Applied PSG")}
                                    </Link>
                                }
                                secondary={`Created: ${new Date(session.createdAt).toLocaleString()}, about ${Math.round((Date.now() - new Date(session.createdAt).getTime()) / 1000 / 60 / 60 / 24)} days ago`}
                            />
                        </ListItem>
                    ))}
                    {doublesetupOpenSessions.length === 0 && (
                        <Typography align="center" color="textSecondary">
                            Currently you have no open sessions.
                        </Typography>
                    )}
                </List>
            </Box>  

            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h5" gutterBottom>
                    Closed Sessions
                </Typography>
                
                {doublesetupClosedSessions.length <= 3 && (
                    <Typography align="center" color="textSecondary">
                        You will be able to review the results of your sessions when you have scored four sessions.
                    </Typography>
                )}

                {doublesetupClosedSessions.length > 3 && ( 
                <List>
                    {doublesetupClosedSessions.map(session => (
                        <ListItem key={session.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                            <ListItemText
                                primary={
                                    <Link href={`/scoringsession/${session.id}/results`} underline="hover" color="primary">
                                        {`${session.experimentName.replace("DOUBLESETUP_", "")} - Go to results`.replace("SAS", "Self-Applied PSG")}
                                    </Link>
                                }
                                secondary={`Created: ${new Date(session.createdAt).toLocaleString()}, about ${Math.round((Date.now() - new Date(session.createdAt).getTime()) / 1000 / 60 / 60 / 24)} days ago`}
                            />
                        </ListItem>
                    ))}
                    
                </List>
                )}
            </Box>
        </Box>
    );
};

export default ExperimentalSetupSessions;
