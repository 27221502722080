import { GetScoringSessionSegmentationById } from "../../../api/scoringAPI";
import { SignalDataSingle } from "../../../utils/types";
import { Signal } from "./types";

const FFT = require('fft.js');

export const canvasScale = 2;
// export function drawVerticalTimeLines(ctx:any, totalSeconds:number, pxPerSecond:any, startSeconds:number|null=null, endSeconds:number|null=null, darkMode=false) {
//   ctx.save();

//   startSeconds = startSeconds || 0;
//   if(startSeconds){
//     endSeconds = startSeconds + totalSeconds;
//   } else {
//     endSeconds = endSeconds || totalSeconds;
//     startSeconds = 0;
//   }
//   if(startSeconds > endSeconds) { throw new Error("startSeconds cannot be greater than endSeconds"); }
  
//   for (let sec = 0; sec <= totalSeconds; sec++) {
    
//     let x = sec * pxPerSecond;

//     ctx.beginPath();
//     ctx.moveTo(x, 0);
//     ctx.lineTo(x, ctx.canvas.height);
//     // skip the first line, since it is the start of the timeline
//     if(sec === 0) { continue; }
//     if ( (startSeconds+sec) %15 === 0) {
//       // set width to 2 for prominent markers
//       ctx.lineWidth = 2;
//       ctx.setLineDash([4, 4]); // Dotted line for prominent markers
//       if(darkMode){
//         ctx.strokeStyle = 'rgba(150,150,150,1)';  // Faint line for each second
//       } else {
//         ctx.strokeStyle = 'rgba(0,0,0,1)';
//       }
//     } else {
//       if(totalSeconds > 60) { continue; }
//       ctx.setLineDash([2, 2]);
//       ctx.lineWidth = 1;
//       if(darkMode){
//         ctx.strokeStyle = 'rgba(150,150,150,0.75)';  // Faint line for each second
//       } else {
//         ctx.strokeStyle = 'rgba(0,0,0,1)';  // Faint line for each second
//       }
//     }
//     ctx.stroke();
//     // put text on the bottom of the line
//     ctx.font = "10px Arial";
//     if(darkMode){
//       ctx.fillStyle = "gray";
//     } else {
//       ctx.fillStyle = "black";
//     }
//     ctx.textAlign = "center";

//     // if the startSec + sec is = startSeconds, then it is the start of the timeline, offset the number a bit to the right
//     if((startSeconds + sec) === startSeconds){
//       x += 15;
//     }
//     // if the startSec + sec is = endSeconds, then it is the end of the timeline, offset the number a bit to the left (due to rounding error, the number may not be exactly at the end of the timeline)
    
//     ctx.fillText(startSeconds + sec, x, ctx.canvas.height/1.5 - 2);
    
//     ctx.closePath();

//   }
//   ctx.restore();
// }






export function drawVerticalTimeLines(
  ctx: CanvasRenderingContext2D,
  totalSeconds: number,
  pxPerSecond: number,
  startSeconds: number | null = null,
  endSeconds: number | null = null,
  darkMode = false,
  absoluteStart: Date | null = null
) {
  ctx.save();

  startSeconds = startSeconds || 0;
  if (startSeconds) {
    endSeconds = startSeconds + totalSeconds;
  } else {
    endSeconds = endSeconds || totalSeconds;
    startSeconds = 0;
  }
  if (startSeconds > endSeconds) {
    throw new Error("startSeconds cannot be greater than endSeconds");
  }

  const absoluteStartTime = absoluteStart ? absoluteStart.getTime() : null;
  const startTimeOffset = startSeconds * 1000;

  const padding = 35; // Adjusted padding for better visibility of labels

  for (let sec = 0; sec <= totalSeconds; sec++) {
    let x = sec * pxPerSecond;

    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, ctx.canvas.height);

    let currentDateTime: Date;
    if (absoluteStartTime !== null) {
      const currentTime = absoluteStartTime + startTimeOffset + (sec * 1000);
      currentDateTime = new Date(currentTime);
    } else {
      currentDateTime = new Date(startTimeOffset + (sec * 1000));
    }

    if ((currentDateTime.getSeconds() % 15 === 0) || sec === 0 || sec === totalSeconds) {
      // Set width to 2 for prominent markers
      ctx.lineWidth = 2;
      ctx.setLineDash([4, 4]); // Dotted line for prominent markers
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';  // Faint line for each second
    } else {
      ctx.setLineDash([2, 2]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(0,0,0,0.25)';  // Faint line for each second
    }

    ctx.stroke();

    if (sec % 15 !== 0){
      continue;
    }

    // Put text on the bottom of the line
    ctx.font = "10px Arial";
    if (darkMode) {
      ctx.fillStyle = "gray";
    } else {
      ctx.fillStyle = "black";
    }
    ctx.textAlign = "center";



    // change currentDateTime to UTC
    currentDateTime.setMinutes(currentDateTime.getMinutes() + currentDateTime.getTimezoneOffset());

    // Format the time label to show hours, minutes, and seconds
    const hours = currentDateTime.getHours().toString().padStart(2, '0');
    const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');
    const timeLabel = `${hours}:${minutes}:${seconds}`;

    // Adjust label position to fit within the canvas
    let labelX = x;
    if (sec === 0) {
      labelX += padding; // Nudge the leftmost text to the right
    } else if (sec === totalSeconds) {
      labelX -= padding; // Nudge the rightmost text to the left
    }
    // CANVAS_SCALE
    ctx.fillText(timeLabel, labelX, ctx.canvas.height / canvasScale - 2);

    ctx.closePath();
  }
  ctx.restore();
}










function formatValueWithUnit(value:any, unit:any) {
  // the unit is a string, the value can be verly low so we need to format it with the correct prefix (micro, milli, etc)
  let s = "";
  if (value < 0.001) {
    s = (value * 1000000).toFixed(0) + " µ" + unit;
  } else if (value < 1) {
    s = (value * 1000).toFixed(0) + " m" + unit;
  } else {
    s = value.toFixed(2) + " " + unit;
  }
  return s;
}


export function drawReferenceLine(ctx:any, signal:any, option:any, property:any, darkMode=false, drawValue :boolean=false) {
  if (!option || !property) {
    console.log("option or property is null or undefined");
    return;
  }

  const { referenceLineMax, referenceLineMin } = option;
  const { scale, offset } = property;
  let dotStyle = [2, 2];

  ctx.save();
  ctx.setLineDash(dotStyle);
  // set the line color to a muted, pleasant red  
  let style = "rgba(225, 100, 100, 1)";
  ctx.strokeStyle = style;
  ctx.fillStyle = style;
  ctx.lineWidth = 1;

  let textSize = 15;

  if (referenceLineMax !== null) {
    const yMax = mapYToCanvasSpace(referenceLineMax, scale, offset);  //(referenceLineMax * scale) + offset;
    ctx.beginPath();
    ctx.moveTo(0, yMax);
    ctx.lineTo(ctx.canvas.width, yMax);
    ctx.stroke();
    ctx.closePath();
    if (drawValue) {
      ctx.font = `${textSize}px Arial`;
      ctx.textAlign = "center";
      ctx.fillText(formatValueWithUnit(referenceLineMax, signal.measurementUnit), 25, yMax + 5);
    }
  }

  if (referenceLineMin !== null) {
    const yMin =  mapYToCanvasSpace(referenceLineMin, scale, offset); 
    ctx.beginPath();
    ctx.moveTo(0, yMin);
    ctx.lineTo(ctx.canvas.width, yMin);
    ctx.stroke();
    ctx.closePath();
    if (drawValue) {
      ctx.font = `${textSize}px Arial`;
      ctx.textAlign = "center";
      let xval = ctx.canvas.width / canvasScale - 50;
      ctx.fillText(formatValueWithUnit(referenceLineMin, signal.measurementUnit), xval, yMin + 5);
    }
  }
  
  ctx.restore();
}



export function findClosestSignal(
  time: number, mouseY: number, signals: any[], signalProperties: { offset: number; scale: number }[], canvasWidth: number, canvasHeight: number
): { closestIndex: number | null; closestTime: number | null; minDistance: number } {
  let closestIndex: number | null = null;
  let minDistance = Number.MAX_VALUE;
  let closestTime: number | null = null;

  if (signals.length === 0 || signalProperties.length === 0) {
    return { closestIndex, closestTime, minDistance };
  }

  

  const totalDurationInSeconds = signals[0].values.length / signals[0].samplingFrequency;
  const pixelsPerSecond = canvasWidth / totalDurationInSeconds;

  signals.forEach((signal, index) => {
    if (!signalProperties[index]) return;

    const { values, samplingFrequency } = signal;
    const { scale, offset } = signalProperties[index];

    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const timeInSeconds = i / samplingFrequency;
      const xPixels = timeInSeconds * pixelsPerSecond;
      const yPixels = mapYToCanvasSpace(value, scale, offset);

      const distance = Math.sqrt(
        Math.pow(xPixels - (time * pixelsPerSecond), 2) +
        Math.pow(yPixels - mouseY, 2)
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestIndex = index;
        closestTime = timeInSeconds;
      }
    }
  });

  return { closestIndex, closestTime, minDistance };
}

export function mapYToCanvasSpace(value:any, scale:any, yOffset:any) {
  var y = (-value * scale) + yOffset;
  return y;
}







type WaveBand = {
  low: number;
  high: number;
};

const waveBands: { [key: string]: WaveBand } = {
  delta: { low: 0.5, high: 2 },
  theta: { low: 4, high: 8 },
  alpha: { low: 8, high: 12 },
  beta: { low: 12, high: 30 },
};

type DetectionMap = {
  [key: string]: boolean[];
};
function nearestPowerOfTwo(n: number): number {
  return Math.pow(2, Math.ceil(Math.log2(n)));
}

function resizeSignal(values: number[], newSize: number): number[] {
  const newValues = new Array(newSize).fill(0);
  for (let i = 0; i < values.length; i++) {
    newValues[i] = values[i];
  }
  return newValues;
}

function createOverlappingWindows(values: number[], windowSize: number, overlap: number): number[][] {
  const windows = [];
  const stepSize = Math.floor(windowSize * (1 - overlap));

  for (let start = 0; start < values.length - windowSize + 1; start += stepSize) {
    windows.push(values.slice(start, start + windowSize));
  }

  return windows;
}

export function detectWaveBands(values: number[], samplingRate: number): DetectionMap {
  let windowSize = 2**8;
  let overlap = 0.3;

  const windows = createOverlappingWindows(values, windowSize, overlap);
  const detectionMap: DetectionMap = {
    delta: new Array(values.length).fill(false),
    theta: new Array(values.length).fill(false),
    alpha: new Array(values.length).fill(false),
    beta: new Array(values.length).fill(false),
  };

  // Define thresholds for each wave band
  const thresholds:any  = {
    delta: 0.0025,
    theta: 0.001,
    alpha: 0.0006,
    beta: 0.0004,
  };
  
  windows.forEach((window, windowIndex) => {
    const fftSize = nearestPowerOfTwo(windowSize);
    const resizedWindow = resizeSignal(window, fftSize);
    const fft = new FFT(fftSize);
    const complexArray = fft.createComplexArray();
    const realArray = resizedWindow.slice(); // Copy the real part of the values

    fft.realTransform(complexArray, realArray);
    fft.completeSpectrum(complexArray);

    const frequencies = new Array(fftSize / 2);
    const magnitudes = new Array(fftSize / 2);

    for (let i = 0; i < fftSize / 2; i++) {
      const real = complexArray[2 * i];
      const imag = complexArray[2 * i + 1];
      frequencies[i] = (i * samplingRate) / fftSize;
      magnitudes[i] = Math.sqrt(real * real + imag * imag);
    }

    const startIndex = windowIndex * Math.floor(windowSize * (1 - overlap));

    Object.keys(waveBands).forEach(band => {
      const { low, high } = waveBands[band];
      const windowDetection = new Array(windowSize).fill(false);
      const bandThreshold = thresholds[band];

      for (let i = 0; i < frequencies.length; i++) {
        if (frequencies[i] >= low && frequencies[i] <= high && magnitudes[i] > bandThreshold) {
          for (let j = 0; j < windowSize; j++) {
            windowDetection[j] = true;
          }
          break; // Stop further checking once waves are detected in this window
        }
      }

      for (let i = 0; i < windowDetection.length; i++) {
        if (windowDetection[i] && (startIndex + i) < detectionMap[band].length) {
          detectionMap[band][startIndex + i] = true;
        }
      }
    });
  });

  
  return detectionMap;
}














const waveBandColors: { [key: string]: string } = {
  delta: "rgba(0, 0, 139, 0.4)", // Darker transparent blue for delta
  theta: "rgba(0, 255, 0, 0.1)", // Very transparent green for theta
  alpha: "rgba(255, 0, 0, 0.1)", // Very transparent red for alpha
};

const waveBandBorderColors: { [key: string]: string } = {
  delta: "rgba(0, 20, 139, 0.8)", // Darker blue border for delta
  theta: "rgba(0, 255, 0, 0.8)", // Thin green border for theta
  alpha : "rgba(255, 56, 0, 0.6)", // Thin red border for alpha
};

const signalTypeColorMap: { [key: string]: string } = {
  "EEG": "rgba(70, 130, 180, 1)", // Darker, muted pastel blue
  "EOG": "rgba(46, 139, 87, 1)",  // Darker, muted pastel green
};

const fallbackSignalColor = "rgba(105, 105, 105, 1)"; // Dark gray color

export function drawSignal(
  context: CanvasRenderingContext2D,
  signal: Signal,
  properties: { scale: number; offset: number, highlightInterestWaves?: boolean},
  darkMode = false,
  backgroundColor?: string
) {
  const { values, signalTypeName } = signal;
  const { scale, offset } = properties;
  // highlightInterestWaves is true if it's in the properties, otherwise it's false
  const highlightInterestWaves = properties.highlightInterestWaves || false;
  const canvasWidth = context.canvas.width;
  const valuesLength = values.length;
  const increment = (canvasWidth/canvasScale) / valuesLength;



  let detectionMap: DetectionMap = { delta: [], theta: [], alpha: [], beta: []};

  if (signalTypeName.toUpperCase().includes("EEG") && highlightInterestWaves) {
    // detectionMap = //detectWaveBands(values, samplingFrequency);
  }

  // Determine the signal color
  let signalColor = fallbackSignalColor;
  for (const key in signalTypeColorMap) {
    if (signalTypeName.toUpperCase().includes(key)) {
      signalColor = signalTypeColorMap[key];
      break;
    }
  }

  // Draw the signal with delta highlights
  context.save();
  let x = 0;
  let y = mapYToCanvasSpace(values[0], scale, offset);
  context.strokeStyle = signalColor;
  context.lineWidth = 0.75;

  context.beginPath();
  context.moveTo(x, y);

  for (let i = 1; i < valuesLength; i++) {
    x += increment;
    y = mapYToCanvasSpace(values[i], scale, offset);
    context.lineTo(x, y);
  }

  


  context.stroke();
  context.closePath();
  context.restore();

  // Draw EEG annotations if the signal type is EEG
  if (signalTypeName.toUpperCase().includes("EEG")) {
    drawEEGAnnotations(context, detectionMap, scale, offset, increment, values);
  }
}

















function drawDetectionPolygon(
  context: CanvasRenderingContext2D,
  start: number,
  end: number,
  band: string,
  fillColor: string,
  scale: number,
  offset: number,
  increment: number,
  values: number[]
) {
  // Input validation
  if (!context || typeof context !== 'object') throw new Error('Invalid canvas context');
  if (typeof start !== 'number' || typeof end !== 'number' || start >= end) throw new Error('Invalid start or end values');
  if (!Array.isArray(values) || values.length === 0) throw new Error('Invalid values array');
  if (typeof fillColor !== 'string' || typeof band !== 'string') throw new Error('Invalid fillColor or band');
  if (typeof scale !== 'number' || typeof offset !== 'number' || typeof increment !== 'number') throw new Error('Invalid scale, offset or increment');

  const regionLength = end - start;
  const minVertices = 20; // Increased min vertices
  let step = Math.max(1, Math.floor(regionLength / (minVertices / 2)));

  // Ensure an even number of steps
  if ((end - start) % step !== 0) {
    step = Math.max(1, Math.floor(regionLength / (minVertices / 2)) + 1);
  }

  const xCoords = [];
  for (let i = start; i <= end; i += step) {
    xCoords.push(i * increment);
  }

  const upperCoords = [];
  const lowerCoords = [];

  // Calculate upper and lower coordinates
  for (let i = 0; i < xCoords.length; i++) {
    const x = xCoords[i];
    const segmentStart = start + i * step;
    const segmentEnd = Math.min(segmentStart + step, end);

    let maxValue = -Infinity;
    let minValue = Infinity;

    for (let j = segmentStart; j < segmentEnd; j++) {
      const value = values[j];
      if (value > maxValue) maxValue = value;
      if (value < minValue) minValue = value;
    }

    upperCoords.push({ x, y: mapYToCanvasSpace(maxValue, scale, offset) });
    lowerCoords.push({ x, y: mapYToCanvasSpace(minValue, scale, offset) });
  }

  // Ensure the last point is included if it wasn't already and that we have an even number of vertices
  if (upperCoords.length < minVertices / 2 || upperCoords.length % 2 !== 0) {
    const lastValue = values[end - 1];
    const lastX = (end - 1) * increment;
    upperCoords.push({ x: lastX, y: mapYToCanvasSpace(lastValue, scale, offset) });
    lowerCoords.push({ x: lastX, y: mapYToCanvasSpace(lastValue, scale, offset) });
  }

  // Combine upper and lower coordinates to form the polygon
  const polygonCoords = upperCoords.concat(lowerCoords.reverse());

  // Draw the polygon on the canvas
  context.save();
  context.fillStyle = fillColor;
  context.strokeStyle = waveBandBorderColors[band];
  context.lineWidth = 1;

  context.beginPath();
  context.moveTo(polygonCoords[0].x, polygonCoords[0].y);
  polygonCoords.forEach(coord => context.lineTo(coord.x, coord.y));
  context.closePath();

  context.fill();
  context.stroke();
  context.restore();
}














// Function to draw EEG annotations
function drawEEGAnnotations(
  context: CanvasRenderingContext2D,
  detectionMap: DetectionMap,
  scale: number,
  offset: number,
  increment: number,
  values: number[]
) {
  const importantBands = ['alpha'];
  
  importantBands.forEach(band => {
    let start = -1;
    for (let i = 0; i < detectionMap[band].length; i++) {
      if (detectionMap[band][i]) {
        if (start === -1) start = i;
      } else {
        if (start !== -1) {
          drawDetectionPolygon(context, start, i, band, waveBandColors[band], scale, offset, increment, values);
          start = -1;
        }
      }
    }
    if (start !== -1) {
      drawDetectionPolygon(context, start, detectionMap[band].length, band, waveBandColors[band], scale, offset, increment, values);
    }
  });
}











export function drawReferenceArea(context:any, canvasStart:number, canvasEnd:number){
  if(canvasStart === canvasEnd) { return; }
  context.save();
  context.beginPath();
  context.rect( canvasStart, 0, canvasEnd , context.canvas.height);
  context.fillStyle = 'rgba(0, 0, 0, 0.15)';
  context.fill();
  context.closePath();
  context.restore();
}

export function calculateScaleChange(initialY:any, currentY:any, initialScale:any) {
  const scaleChange = (currentY - initialY) / 1000;
  const newScale = initialScale + scaleChange; 
  return Math.max(0.1, Math.min(newScale, 10)); 
}

export function calculateNewOffset(values: any, rectHeight: any, oldScale: any, newScale: any, oldOffset: any, cursorY: any) {
  if (!Array.isArray(values) || values.length === 0) {
    // Handle the case where values are undefined or empty
    return oldOffset; // or some default value if needed
  }

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  
  // Guard against min and max being the same, which would make the range 0 and cause issues with mapping
  if (minValue === maxValue) {
    return oldOffset; // or another default behavior
  }

  const meanValue = values.reduce((a: any, b: any) => a + b, 0) / values.length;
  const oldY = mapYToCanvasSpace(meanValue, minValue, maxValue);
  const newY = mapYToCanvasSpace(meanValue, minValue, maxValue);

  return oldOffset - (newY - oldY);
}

export function enforceCanvasBounds(y:any, rectHeight:any) {
  return Math.max(-rectHeight, Math.min(rectHeight, y));
}

export function drawInfoReferenceArea(context: any, canvasStart: any, canvasEnd: any, text: string = "👨‍🔬 N1", centreLocation: any = null, darkMode:boolean=false, fillStyle:string|null=null) {
  if (canvasStart === canvasEnd) { return; }
  
  context.save();
  context.beginPath();
  const width = canvasEnd - canvasStart;
  context.rect(canvasStart, 0, width, context.canvas.height);
  if(fillStyle){
      context.fillStyle = 'rgba(255, 215, 0, 0.6)';
  } else {
      context.fillStyle = 'rgba(0, 0, 0, 0)';
  }
  context.fill();
  context.closePath();
  
  const fontsize = Math.min(200, width / 4);
  context.font = `${fontsize}px Arial`;
  
  context.fillStyle = "rgba(73, 100, 112, 0.2)";
  
  context.textAlign = "center";

  if (!centreLocation) {
      centreLocation = canvasStart + width / 2;
  }
  context.fillText(text, centreLocation, context.canvas.height / 2);
  context.restore();
}



export function prependSignalsNonCategorised(oldSignals:any, newSignals:any){
	let signals:SignalDataSingle[] = [];
	newSignals.forEach((signal:SignalDataSingle) => {
		let {signalName, values } = signal;
		// find matching signal in signals which is oldSignals
		let oldSignal = oldSignals.find((s:any) => s.signalName === signalName);
		if(!oldSignal){ throw new Error(`Signal ${signalName} not found in oldSignals`); }

		// prepend the values in newSignals to the values in the oldSignals
		let newValues = [...values, ...oldSignal.values];
		// replace the values in oldSignal, since it is a reference to the original signal
		oldSignal.values = newValues;
		signals.push(oldSignal);
		
		
	});	
	// for (let signal of signals){
	// 	// console.log(signal);	
	// }
	return signals;
}	

export function appendSignalsNonCategorised(oldSignals:any, newSignals:any){

	let signals:SignalDataSingle[] = [];
	oldSignals.forEach((signal:SignalDataSingle) => {
		let {signalName } = signal;
		// find matching signal in signals which is newSignals
		let newSignal = newSignals.find((s:any) => s.signalName === signalName);
		if(!newSignal){ throw new Error(`Signal ${signalName} not found in newSignals`); }

		// append the values in newSignals to the values in the oldSignals
		let newValues = [...signal.values, ...newSignal.values];
		// replace the values in oldSignal, since it is a reference to the original signal
		signal.values = newValues;
		signals.push(signal);
		
	});	
	return signals;
}	












export function redrawInfoCanvas(infoCanvasRef:any, signals:any[], signalProperties:any[]) {
  if (!infoCanvasRef.current) return;


  const infoContext = infoCanvasRef.current.getContext('2d');
  if (!infoContext) return;

  infoContext.clearRect(0, 0, infoCanvasRef.current.width, infoCanvasRef.current.height);

  infoContext.fillStyle = '#f8f8f8';
  infoContext.fillRect(0, 0, infoCanvasRef.current.width, infoCanvasRef.current.height);


  // draw each signal name on the left side of the canvas
  signals.forEach((signal, i) => {
    infoContext.fillStyle = 'darkgray';
    infoContext.font = `${15}px Arial`;
    let s = signal.signalName;

    // if the signal is called 1-2, change it to chin EMG
    if (s === "1-2") {
      s = "Chin EMG";
    }

    // if the signal has a negative scale, denote it with a minus sign
    if (signalProperties[i].scale < 0) {
      s = "-" + s;
    }
    // get detection map for the signal
    // draw the percentages onto the correct place in the infocanvas
    // skip it if the infocanvas is not available
    
    
    if (infoCanvasRef.current && signalProperties[i].highlightInterestWaves && signal.signalTypeName.toUpperCase().includes("EEG")) {
      // if the category does not include EEG, continue
      
      const infoContext = infoCanvasRef.current.getContext('2d');
      if (infoContext) {


        let detectionMap = detectWaveBands(signal.values, signal.samplingFrequency);
        let deltaPercentage = detectionMap.delta.filter(d => d).length / detectionMap.delta.length;
        let alphaPercentage = detectionMap.alpha.filter(d => d).length / detectionMap.alpha.length;
        deltaPercentage *= 100;
        alphaPercentage *= 100;
        
        
        infoContext.save();
        infoContext.font = "15px Arial";
        // set the color the same as the alpha color
        infoContext.fillStyle = waveBandColors['alpha'];
        // set the alpha (opacity) to 1
        infoContext.fillStyle = infoContext.fillStyle.replace(/[^,]+(?=\))/, '1');
        infoContext.textAlign = "center";

        // x should be 2 pixels from the left side of the canvas
        // y should be the signal offset + 20 pixels
        let deltax = 25;
        let deltay = signalProperties[i]?.offset + 20;
        let alphax = 75;
        let alphay = signalProperties[i]?.offset + 20;
        
        infoContext.fillText(`α ${alphaPercentage.toFixed(0)}%`, alphax, alphay);
        
        
        
        // set the color the same as the delta color
        infoContext.fillStyle = waveBandColors['delta'];
        infoContext.fillStyle = infoContext.fillStyle.replace(/[^,]+(?=\))/, '1');
        // set the alpha (opacity) to 1
        infoContext.fillText(`Δ ${deltaPercentage.toFixed(0)}%`, deltax, deltay);
        infoContext.restore();
      }
    }


    infoContext.fillText(s, 10, signalProperties[i]?.offset ?? 0);
  });

};  










const alignToInterval = (value: number): number => Math.floor(value / 30) * 30;





// accepts 2 numbers and a setter function
export function moveBrushToLeftSegment(brushStartSeconds:number, brushEndSeconds:number, setBrushStartSeconds:any, setBrushEndSeconds:any, loadedDurationSeconds:number  ) : void {
  const newStart = alignToInterval(brushStartSeconds);
  const newEnd = alignToInterval(brushEndSeconds);

  if (Math.abs(newStart - brushStartSeconds) <= 1) {
      setBrushStartSeconds(brushStartSeconds - 30);
      setBrushEndSeconds(brushEndSeconds - 30);
  } else {
      setBrushStartSeconds(newStart);
      setBrushEndSeconds(newEnd);
  }
};

export function moveBrushToRightSegment(brushStartSeconds:number, brushEndSeconds:number, setBrushStartSeconds:any, setBrushEndSeconds:any, loadedDurationSeconds:number ):void {
  let newStart = alignToInterval(brushStartSeconds + 30);
  let newEnd = alignToInterval(brushEndSeconds + 30);

  if (newEnd > loadedDurationSeconds) {
      console.log("Reached the end of the loaded data");
  } else {
      setBrushStartSeconds(newStart);
      setBrushEndSeconds(newEnd);
  }
};


export function findRequestForScoring(brushStartSeconds:number, brushEndSeconds:number, requests:any, segmentations:any, sessionStart:Date):any {
  let middle = (brushEndSeconds + brushStartSeconds) / 2;

  middle = middle * 1000 + sessionStart.getTime();

  

  const mappedSegmentations = segmentations.map((segmentation: any) => {
      const start = new Date(segmentation.startTimestamp).getTime();
      const end = new Date(segmentation.stopTimestamp).getTime();
      return { ...segmentation, start, end };
  });

  // console.log("Mapped segmentations", mappedSegmentations);

  let segmentationsInBrush = mappedSegmentations.filter((segmentation: any) => segmentation.start <= middle && segmentation.end >= middle);
  // segmentations that don't have the segmentationTypeId of 1 can be ignored
  segmentationsInBrush = segmentationsInBrush.filter((segmentation: any) => segmentation.segmentationTypeId === 1);
  if (segmentationsInBrush.length === 0) {
      throw new Error("No segmentations in brush");
  }
  if (segmentationsInBrush.length > 1) {
      // console.log(segmentationsInBrush);
      throw new Error("Multiple segmentations in brush");
  }

  const segmentation = segmentationsInBrush[0];
  const request = requests.find((req: any) => req.segmentationId === segmentation.id);

  // console.log("Segmentation in brush", segmentation);
  // console.log("Request for segmentation", request);

  return [segmentation, request];
}










export async function LoadNext(segmentations: any[], signals: any[], loadedDurationSeconds: number, sessionStart: Date | null, scoringSessionInt: number): Promise<any> {
  // sort the segmentations by startTimestamp and filter out the ones that are before the end of the loaded data
  // sort the segmentations by startTimestamp
  let sortedSegmentations = segmentations.sort((a: any, b: any) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime())
  // filter out the ones that are before the end of the loaded data
  let loadedDurationTime = new Date(sessionStart!.getTime() + (loadedDurationSeconds * 1000)).getTime() // loadedDurationSeconds in milliseconds
  let padding = 1500; // 1.5 seconds padding

  sortedSegmentations = sortedSegmentations.filter(
    (segmentation: any) => new Date(segmentation.startTimestamp).getTime() > loadedDurationTime - padding  
  );

  const nextSegment = sortedSegmentations[0];
  if (!nextSegment) {
      return;
  }

  
  
  
  
  
  const data = await GetScoringSessionSegmentationById(scoringSessionInt, nextSegment.id);

  const updatedSignals = signals.map((signal: any) => {
      const newSignalFromData = data.signals.find((s: any) => s.signalName === signal.signalName);
      if (!newSignalFromData) throw new Error("Can't find signal in new data");
      if (signal.samplingFrequency !== newSignalFromData.samplingFrequency) throw new Error("Sampling frequencies are not the same");

      return { ...signal, values: signal.values.concat(newSignalFromData.values) };
  });
  // print out the new signal names and the length of the new signals in second using the sampling frequency
  const newSegmentationEnd = new Date(data.segmentations[0].stopTimestamp).getTime();
  const newDuration = (newSegmentationEnd - sessionStart!.getTime()) / 1000;

  if (newDuration < loadedDurationSeconds) throw new Error(`New duration is ${newDuration}, expected ${loadedDurationSeconds + 30}`);
  if(newDuration > loadedDurationSeconds + 30.5) throw new Error(`New duration is ${newDuration}, expected ${loadedDurationSeconds + 30}`);

  // console.log(newDuration, loadedDurationSeconds);
  

  return [updatedSignals, newDuration];
}










// export const alignToInterval = (value: number): number => Math.floor(value / 30) * 30;

// export const isNotNull = <T>(annotation: T | null): annotation is T => annotation !== null;



// export const mapAnnotations = (userScorings: any[], sessionStart: Date): ScoringAnnotation[] => userScorings.map((scoring) => {
//     if (scoring.segmentation === undefined) {
//         return null;
//     }
//     let start = new Date(scoring.segmentation.startTimestamp).getTime() - sessionStart.getTime();
//     let end = new Date(scoring.segmentation.stopTimestamp).getTime() - sessionStart.getTime();
//     start = start / 1000;
//     end = end / 1000;
//     const duration = end - start;
//     return { start, duration, annotation: scoring.scoring };
// }).filter(isNotNull);



























export function drawTrainingAnnotation(annotation: any, backgroundCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>, infoCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>, signalLengthInSeconds: number, brushStartSeconds: number | undefined, brushEndSeconds: number | undefined, padding: number) {
  let start = annotation.start;
  const duration = annotation.duration;

  let end = start + duration;
  const rect = backgroundCanvasRef.current?.getBoundingClientRect();
  
  // if the annotation is not in view, skip it, use the brushStartSeconds and brushEndSeconds to determine if the annotation is in view
  // first, check if the annotation is completely before the brushStartSeconds
  if (brushStartSeconds !== undefined && start + duration < brushStartSeconds) return;
  // then, check if the annotation is completely after the brushEndSeconds
  if (brushEndSeconds !== undefined && start > brushEndSeconds) return;


  // the annotation.annotation is comprised of 3 fields, correctness, prediction, and actual
  let correctness = annotation.annotation.split(" ")[0].toLocaleLowerCase() === "true";
  let userPrediction = annotation.annotation.split(" ")[1]; 
  let actual = annotation.annotation.split(" ")[2];


  // now bound the annotation to the brushStartSeconds and brushEndSeconds
  start = Math.max(start, brushStartSeconds ?? 0);
  end = Math.min(end, brushEndSeconds ?? signalLengthInSeconds);

  //subtract the brushStartSeconds from the start and end to get the start and end in the view
  start -= brushStartSeconds ?? 0;
  end -= brushStartSeconds ?? 0;

  if (!rect) return;
  let startInCanvasSpace = (start / signalLengthInSeconds) * (rect.width );
  let endInCanvasSpace = (end / signalLengthInSeconds) * (rect.width );
  
  // factor in the scaling
  // startInCanvasSpace = startInCanvasSpace / scale;
  // endInCanvasSpace = endInCanvasSpace / scale;

  if (!backgroundCanvasRef.current || !infoCanvasRef.current) return;
  const backgroundContext = backgroundCanvasRef.current.getContext('2d');
  if (!backgroundContext) return;
  
  
  
  // if the prediction is correct, draw a faintly green rectangle, else draw a faintly red rectangle
  let fillStyle = correctness ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)';

  backgroundContext.fillStyle = fillStyle;

  backgroundContext.fillRect(startInCanvasSpace, 0, endInCanvasSpace - startInCanvasSpace, rect.height - padding);
  //draw the text on the canvas in the middle of the annotation
  let middle = annotation.start + annotation.duration / 2;
  middle -= brushStartSeconds ?? 0;
  middle = (middle / signalLengthInSeconds) * (rect.width);
  
  let text = "";
  if (correctness) {
    text = "You correctly predicted " + actual;
  }
  else {
    text = "You incorrectly scored " + userPrediction + " instead of " + actual;
  }
  backgroundContext.fillStyle = 'rgba(0, 0, 0, 0.25)';
  // backgroundContext.font = '80px Arial';

  // dynamically adjust the font size based on the length of the text
  let textlength = backgroundContext.measureText(text).width;
  
  // dynamically adjust the font size so that it fits exactly one epoch
  let fontsize = 25;
  
  backgroundContext.font = fontsize + 'px Arial';
  
  backgroundContext.fillText(text, middle - textlength / 2, 25);
  



}




// for each annotation, draw a colored rectangle on the canvas
export function drawNormalAnnotation(annotation:any, backgroundCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>, infoCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>, signalLengthInSeconds: number, brushStartSeconds: number | undefined, brushEndSeconds: number | undefined, padding: number) {
let start = annotation.start;
const duration = annotation.duration;

let end = start + duration;
const rect = backgroundCanvasRef.current?.getBoundingClientRect();

// if the annotation is not in view, skip it, use the brushStartSeconds and brushEndSeconds to determine if the annotation is in view
// first, check if the annotation is completely before the brushStartSeconds
if (brushStartSeconds !== undefined && start + duration < brushStartSeconds) return;
// then, check if the annotation is completely after the brushEndSeconds
if (brushEndSeconds !== undefined && start > brushEndSeconds) return;
// now bound the annotation to the brushStartSeconds and brushEndSeconds
start = Math.max(start, brushStartSeconds ?? 0);
end = Math.min(end, brushEndSeconds ?? signalLengthInSeconds);

//subtract the brushStartSeconds from the start and end to get the start and end in the view
start -= brushStartSeconds ?? 0;
end -= brushStartSeconds ?? 0;

if (!rect) return;
let startInCanvasSpace = (start / signalLengthInSeconds) * (rect.width );
let endInCanvasSpace = (end / signalLengthInSeconds) * (rect.width );

// factor in the scaling
// startInCanvasSpace = startInCanvasSpace / scale;
// endInCanvasSpace = endInCanvasSpace / scale;

if (!backgroundCanvasRef.current || !infoCanvasRef.current) return;
const backgroundContext = backgroundCanvasRef.current.getContext('2d');
if (!backgroundContext) return;
backgroundContext.fillStyle = 'rgba(0, 0, 0, 0.05)';
backgroundContext.fillRect(startInCanvasSpace, 0, endInCanvasSpace - startInCanvasSpace, rect.height - padding);
//draw the text on the canvas in the middle of the annotation
let middle = annotation.start + annotation.duration / 2;
middle -= brushStartSeconds ?? 0;
middle = (middle / signalLengthInSeconds) * (rect.width);
backgroundContext.fillStyle = 'rgba(0, 0, 0, 0.25)';
backgroundContext.font = '80px Arial';
backgroundContext.fillText(annotation.annotation, middle, 250);
};



export function drawRecommendation(recommendation:any, backgroundCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>, infoCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>, signalLengthInSeconds: number, brushStartSeconds: number | undefined, brushEndSeconds: number | undefined, padding: number) {
let start = recommendation.start;
const duration = recommendation.duration;
let end = start + duration;
const rect = backgroundCanvasRef.current?.getBoundingClientRect();

// if the annotation is not in view, skip it, use the brushStartSeconds and brushEndSeconds to determine if the annotation is in view
if (brushStartSeconds !== undefined && start + duration < brushStartSeconds) return;
if (brushEndSeconds !== undefined && start > brushEndSeconds) return;
// now bound the annotation to the brushStartSeconds and brushEndSeconds
start = Math.max(start, brushStartSeconds ?? 0);
end = Math.min(end, brushEndSeconds ?? signalLengthInSeconds);

//subtract the brushStartSeconds from the start and end to get the start and end in the view
start -= brushStartSeconds ?? 0;
end -= brushStartSeconds ?? 0;

if (!rect) return;
let startInCanvasSpace = (start / signalLengthInSeconds) * rect.width;
let endInCanvasSpace = (end / signalLengthInSeconds) * rect.width;

if (!backgroundCanvasRef.current || !infoCanvasRef.current) return;
const backgroundContext = backgroundCanvasRef.current.getContext('2d');
if (!backgroundContext) return;

if(!recommendation.sideline) {
  backgroundContext.fillStyle = 'rgba(0, 255, 0, 0.05)';
  backgroundContext.fillRect(startInCanvasSpace, 0, endInCanvasSpace - startInCanvasSpace, rect.height - padding);
}

let middle = recommendation.start + recommendation.duration / 2;
middle -= brushStartSeconds ?? 0;
middle = (middle / signalLengthInSeconds) * rect.width;
backgroundContext.fillStyle = 'rgba(0, 0, 0, 0.25)';

let human_emoji = ["🧑‍⚕️", "👩‍⚕️"];
// make the index of the emoji random, but controlled by the start time of the recommendation so that the same recommendation always gets the same emoji
// the start is always a multiple of 30, so we can't use the start directly, but need to perform some funny math to get a number between 0 and human_emoji.length
// but not make it very obvious that the emoji is based on the start time
let pstart = Math.floor(recommendation.start / 30);
let index_for_emoji = pstart % human_emoji.length;
let human = human_emoji[index_for_emoji];

recommendation.annotation = recommendation.annotation.replace(/human/gi, human);
recommendation.annotation = recommendation.annotation.replace(/ai/gi, '🤖');

const visibleDuration = end - start;
if (recommendation.sideline && visibleDuration >= 2) { // Only show sidelined if at least 2 seconds are visible
  // Adjust font and measure text for sideline
  backgroundContext.font = '25px Arial';
  let textlength = backgroundContext.measureText(recommendation.annotation).width;
  let textheight = backgroundContext.measureText(recommendation.annotation).actualBoundingBoxAscent;
  
  // Draw text at the corners of the current epoch
  backgroundContext.fillText(recommendation.annotation, startInCanvasSpace + 10, 30); // Top-left corner of epoch
  backgroundContext.fillText(recommendation.annotation, endInCanvasSpace - textlength - 10, 30); // Top-right corner of epoch
  backgroundContext.fillText(recommendation.annotation, startInCanvasSpace + 10, rect.height - textheight - 10); // Bottom-left corner of epoch
} else if (!recommendation.sideline) {
  // Adjust font for main text
  backgroundContext.font = '80px Arial';
  let textlength = backgroundContext.measureText(recommendation.annotation).width;
  middle -= textlength / 2;
  
  backgroundContext.fillText(recommendation.annotation, middle, rect.height / 4);
  backgroundContext.fillText(recommendation.annotation, middle, rect.height / 2);
  backgroundContext.fillText(recommendation.annotation, middle, rect.height * 3 / 4);
}
};