import React, { useState, useEffect } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    useTheme,
} from "@mui/material";
// import Brightness4Icon from "@mui/icons-material/Brightness4";
// import Brightness7Icon from "@mui/icons-material/Brightness7";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate, useLocation } from "react-router-dom";
import { getUserIdentity } from "../../api/authAPI";

interface NavigationButtonsProps {
    darkMode: boolean;
    setDarkMode: (mode: boolean) => void;
    user: string | null;
    logout: () => void;
    isCollapsable?: boolean;
}

const NavigationBar: React.FC<NavigationButtonsProps> = ({
    darkMode,
    setDarkMode,
    user,
    logout,
    isCollapsable = false,
}) => {
    const [isNavVisible, setIsNavVisible] = useState(true);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        // Only add mouse move listener if the path contains 'scoring' or 'tutorial'
        if ( location.pathname.includes('scoring') || location.pathname.includes('tutorial') || location.pathname.includes('training'))  {
            const handleMouseMove = (e: MouseEvent) => {
                if (e.clientY < 2) {
                    setIsNavVisible(true);
                } else if (e.clientY > 60) {
                    setIsNavVisible(false);
                }
            };

            document.addEventListener("mousemove", handleMouseMove);

            return () => {
                document.removeEventListener("mousemove", handleMouseMove);
            };
        }
    }, [location]);

    useEffect(() => {
        getUserIdentity().then(data => {
            setIsAdmin(data.isAdministrator);
        }).catch(err => {
            console.log("I am going to navigate to / in the NavigationBar.tsx file");
            window.location.href = "/";
        });
    }, [setIsAdmin]);


    // const handleThemeChange = () => {
    //     setDarkMode(!darkMode);
    // };

    const handleAdminClick = () => {
        navigate("/supersecretadminpanel");
    };

    const handleHomeButtonClick = () => {
        navigate("/home");
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    const Spacer = () => <div style={{ flexGrow: 1 }} />;

    // Determine AppBar style based on the URL path and mouse hover state
    const appBarStyle = {
        transform: (location.pathname.includes('scoring') ||location.pathname.includes('tutorial')  || location.pathname.includes('training')  ) && !isNavVisible
            ? "translateY(-95%)" : "translateY(0)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
        }),
    };


    return (
        <AppBar position= {(location.pathname.includes('scoring') ||location.pathname.includes('tutorial') || location.pathname.includes('training')  ) ? "fixed":"relative"} style={appBarStyle}>
            <Toolbar>
                <div style={{ display: "flex", flexGrow: 1 }}>
                    <IconButton onClick={handleBack} color="inherit">
                        <ArrowBackIcon />
                    </IconButton>
                    <Spacer />
                    <Spacer />
                </div>

                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleHomeButtonClick}
                    style={{ cursor: "pointer" }}
                >
                    <Typography variant="h5" component="div">
                        MicroNyx
                    </Typography>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    {/* <IconButton onClick={handleThemeChange} color="inherit">
                        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton> */}

                    {user && (
                        <IconButton onClick={handleHomeButtonClick} color="inherit">
                            <HomeIcon />
                        </IconButton>
                    )}

                    {user && (
                        <IconButton onClick={handleLogout} color="inherit">
                            <LogoutIcon />
                        </IconButton>
                    )}

                    {isAdmin && (
                        <IconButton onClick={handleAdminClick} color="inherit">
                            <AdminPanelSettingsIcon />
                        </IconButton>   
                    )}

                </div>
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
