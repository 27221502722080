import React, { useState, useEffect, lazy, Suspense } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
// import Login from "./components/Authentication/Login";
import { getUserIdentity } from "./api/authAPI";
import NavigationBar from "./components/Navigation/NavigationBar";
import Index from "./components/Index/Index";
import TOS from "./components/LandingPage/TOS";
import PrivacyPolicy from "./components/LandingPage/PrivacyPolicy";
import UserProfile from "./components/Admin/UserProfile";
import ScoringSession from "./components/Scoring/CanvasScoring/ScoringSession";
import TutorialScoringSession from "./components/Scoring/CanvasScoring/TutorialScoringSession";
import TrainingSession from "./components/Scoring/CanvasScoring/TrainingSession";
import Instructions from "./components/Index/ScoringSessions/ExperimentalScoringSessionInstructions/Instructions";

interface AppProps {
    darkMode: boolean;
    setDarkMode: (mode: boolean) => void;
}

const LandingPage = lazy(() => import("./components/LandingPage/LandingPage"));
// const Scoring = lazy(() => import("./components/Scoring/Scoring"));
const AdminPage = lazy(() => import("./components/Admin/AdminPage"));
const DatasetDetailsPage = lazy(() => import("./components/Dataset/DatasetDetailPage"));
const Register = lazy(() => import("./components/Authentication/Register"));
const ForgotPassword = lazy(() => import("./components/Authentication/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/Authentication/ResetPassword"));
const BottomBar = lazy(() => import("./components/Navigation/BottomBar"));
const Verify = lazy(() => import("./components/Authentication/Verify"));
const Login = lazy(() => import("./components/Authentication/Login"));
const ScreenCalibration = lazy(() => import("./components/ScreenCalibration/ScreenCalibration"));
const NewScoring = lazy(() => import("./components/Scoring/CanvasScoring/NewScoring"));
const RecordingManagement = lazy(() => import("./components/Dataset/RecordingManagement"));
const ExperimentManagement = lazy(() => import("./components/Experiment/ExperimentManagement"));
const ScoringSessionResults = lazy(() => import("./components/Index/ScoringSessions/results"));


const App: React.FC<AppProps> = ({ darkMode, setDarkMode }) => {
    const [user, setUser] = useState<string | null>(null);
    const [identity, setIdentity] = useState<any | null>(null); 
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        const checkUserIdentity = async () => {
            setIsLoading(true);
            const token = localStorage.getItem("user");
            if (!token) {
                setUser(null);
                setIsLoading(false);
                return;
            }

            try {
                const identity = await getUserIdentity();
                if (identity) {
                    setUser(token);
                    setIdentity(identity);
                } else {
                    throw new Error("Invalid token");
                }
            } catch (error) {
                console.error("Token validation failed:", error);
                localStorage.removeItem("user");
                localStorage.removeItem("userId");
                setUser(null);
            }
            setIsLoading(false);
        };

        checkUserIdentity();
    }, [setUser, setIdentity, setIsLoading]);

    if (isLoading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    const isNavBarCollapsable = true;

    // const login = async (email: string, password: string) => {
    //     try {
    //         const user = await authenticateUser(email, password);
    //         setUser(user);
    //         const userId = parseJwt(user).id;
    //         localStorage.setItem("user", user);
    //         localStorage.setItem("userId", userId.toString());
    //     } catch (error) {
    //         console.error("Failed to log in:", error);
    //         throw error;
    //     }
    // };

    const setToken = (token: string) => {
        console.log("setting token",token);
        
        setUser(token)
        localStorage.setItem("user", token); 
        window.location.href = "/";
    };

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        setUser(null);
    };

    console.log(identity);
    

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                {identity && 
                    <NavigationBar
                        darkMode={darkMode} setDarkMode={setDarkMode}
                        user={user} logout={logout}
                        isCollapsable={isNavBarCollapsable}
                    />
                }
                
                <Routes>
                    { identity && <>
                        {/* <Route path="/scoring/:experimentId" element={<Scoring userId={identity.userId} darkMode={darkMode} />} /> */}
                        <Route path="/scoring/:experimentId" element={<NewScoring userId={identity.userId} darkMode={darkMode} />} />
                        <Route path="/scoringsession/:scoringSessionId" element={<ScoringSession userId={identity.userId} darkMode={darkMode} />} />
                        <Route path="/tutorial/:scoringSessionId" element={<TutorialScoringSession userId={identity.userId} darkMode={darkMode} />} />
                        <Route path="/management/recording/:recordingId" element={<RecordingManagement />} />
                        <Route path="/management/experiment/:experimentId" element={<ExperimentManagement />} />
                        <Route path="/management/user/profile/:userId" element={<UserProfile /> } />
                        <Route path="/scoringsession/:scoringSessionId/results" element={<ScoringSessionResults />} />
                        <Route path="/training/:scoringSessionId" element={<TrainingSession userId={identity.userId} darkMode={darkMode} />} />
                    </>
                    }
                    <Route path="/instructions" element={<Instructions />} />
                    <Route path="/supersecretadminpanel"element={user ? <AdminPage /> : <Login setToken={setToken} />} />
                    <Route path="/datasets/:id" element={<DatasetDetailsPage />}/>
                    <Route path="/signup" element={<Register setToken={setToken}/>} />
                    <Route path="/account/verify/:token" element={<Verify />} /> 
                    <Route path="/account/reset/:token" element={<ResetPassword />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/" element={!user ? (<LandingPage />) : (<Index />)} />
                    <Route path="/login" element={<Login setToken={setToken} />} />
                    <Route path="/terms" element={<TOS />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<Navigate to="/" />} />

                    <Route path="/screen-calibration" element={<ScreenCalibration />} />
                </Routes>
                {/* Add a small vertical spacing */}
                {!identity &&
                    <div style={{ height: "50px" }} />
                }
                
                {!identity && 
                    <BottomBar />
                }

            </Suspense>
        </Router>
    );
};

export default App;
