import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Avatar } from '@mui/material';
import { getUserIdentity } from '../../../api/authAPI';
import styles from "./Profile.module.css";
// import ActivityVisualiser from './ActivityVisualiser';




interface User {
    isAdministrator: boolean;
    userName: string | null;
    email: string;
}

const UserProfile: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    // const [activity, setActivity] = useState<any | null>(null);

    useEffect(() => {
        getUserIdentity().then(data => {
            setUser(data);
        }).catch(err => {
            console.log("I am going to navigate to /");
            window.location.href = "/";
        });

        
        
        
        
    }, []);


    // useEffect(() => { 
    //     GetUserActivity().then(data => {
    //         console.log(data);
    //         setActivity(data);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }, []);



    if (!user) {
        return <div className={styles.loader}></div>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
            <Avatar sx={{ width: 100, height: 100, mb: 2 }} />
            <Paper sx={{ p: 2, width: '100%', maxWidth: 500 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    {user.userName || "No Username"}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 1 }}>
                    {user.email}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    {user.isAdministrator ? 'Administrator' : 'User'}
                </Typography>
                {/* Add more device information as needed */}
            </Paper>

            {/* <ActivityVisualiser activity={activity} />   */}

        </Box>
    );
};

export default UserProfile;
