
// interface SignalRecommendation {
//     strategy: string;
//     answer: string;
// }

import { ScoringSessionDataResponse } from "../utils/types";
import { processChannel } from "./common";


interface ScoringParams {
    // id: number | null;
    scoringType: string;
    segmentationId: number;
    backtracking: number;
    recordingId: number;
    // requestId: number;
    experimentId: number;
}


interface DeviceInfo {
    browser: string;
    os: string;
    deviceType: string;
    screenResolution: string;
    viewport: string;
    // screenWidthCm: string;
    // screenHeightCm: string;
    // cookies: string;
    // connection: string;
    // ipAddress: string;
}

interface AnalyticsData {
    DeviceInfo: DeviceInfo;
    ScoringElapsedTime: number;
}
interface ScoringData extends ScoringParams  {
}
interface CombinedRequestBody {
    analytics: AnalyticsData,
    scoring: ScoringData,
}



export async function postScoring(requestBody: CombinedRequestBody) {
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}Scoring/UserScorings`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
        throw new Error("Error while posting scoring");
    }

    

    const data = await response.json();

    console.log("GOING OUT OF POSTSCORING");
    return data;
}

export async function postExpertScoring(scoringJson:File, recordingId:number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}file/upload-scoring-json?recordingId=${recordingId}`;
    const formData = new FormData();
    formData.append('scoringJson', scoringJson);
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: formData,
    });

    if (!response.ok) {
        throw new Error("Error while posting scoring");
    }

    const data = await response.json();

    return data;
}









export async function CreateScoringSession(experimentId:number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${experimentId}`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error("Error while creating scoring session");
    }

    return await response.json();
}



export async function CreateTutorialScoringSession(){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/tutorial`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error("Error while creating tutorial scoring session");
    }

    return await response.json();
}

export async function CreateAlternatingScoringSession(){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/alternating`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("Error while posting scoring");
    }
    return await response.json();
}


export async function CreateTrainingScoringSession(){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/training`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("Error while posting scoring");
    }

    return await response.json();

}









export async function GetScoringSessionsForLocalUser(){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error("Error while getting scoring sessions");
    }

    const data = await response.json();

    return data;
}





export async function GetScoringSessionDataById(scoringSessionId:number) : Promise<ScoringSessionDataResponse>{
    const token = localStorage.getItem("user");

    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${scoringSessionId}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error("Error while getting scoring sessions");
    }
    return await response.json();
    
}

export async function GetScoringSessionById(scoringSessionId:number){
    const data = await GetScoringSessionDataById(scoringSessionId);

    const downsampleThreshold = 0;
    let originalSegmentDuration = data.signals[0].values.length / data.signals[0].samplingFrequency;

    // let t1 = Date.now();
    let signals = data.channels.map(channel => processChannel(channel, data, downsampleThreshold));
    // console.log("Time taken to process channels: ", Date.now() - t1);
    // remove all signals that are null
    let signals_notnull = signals.filter(signal => signal !== null);


    // apply voltage filter to all signals

    // signals_notnull = signals_notnull.map(signal => applyVoltageFilter(signal as SignalDataSingle, data.signalTypeOptions));
    

    let segid = signals[0]?.segmentationId || -1;
    let recid = signals[0]?.recordingId || -1;
    

    // if NaN is in values log it
    return {
        signals: signals_notnull,
        requestId: data.requestId,
        signalTypeOptions: data.signalTypeOptions,
        answers: data.answers || [],
        signalNames: signals_notnull,
        segmentLengthInSeconds: originalSegmentDuration,
        segmentationId: segid,
        recordingId: recid,
        segmentations: data.segmentations,
        requests: data.requests,
        start: data.start,
        experimentId: data.experimentId,
        end: data.end,
        priorStage: data.priorStage,
    };


}



async function GetScoringSessionSegmentationIdDataById(scoringSessionId:number, segmentationId:number) : Promise<ScoringSessionDataResponse>{
    const token = localStorage.getItem("user");

    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${scoringSessionId}/segmentation/${segmentationId}`;
    try{
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        // console.log(response);
    
    
        if (!response.ok) {
            
            
            throw new Error("Error while getting scoring sessions");
        }
        return await response.json();
    }
    catch(e){
        console.log("ERRORINO");
        
        console.log(e);
    }
    throw new Error("Error while getting scoring semgent for sessions");
}


export async function GetScoringSessionSegmentationById(scoringSessionId:number, segmentationId:number){


    // let t1 = Date.now();
    const data = await GetScoringSessionSegmentationIdDataById(scoringSessionId, segmentationId);
    // console.log("Seconds taken to get data: ", (Date.now() - t1) / 1000);

    const downsampleThreshold = 0;
    let originalSegmentDuration = data.signals[0].values.length / data.signals[0].samplingFrequency;

    // t1 = Date.now();
    let signals = data.channels.map(channel => processChannel(channel, data, downsampleThreshold));
    // console.log("Time taken to process channels: ", (Date.now() - t1)/1000);
    // remove all signals that are null
    let signals_notnull = signals.filter(signal => signal !== null);


    // apply voltage filter to all signals
    // t1 = Date.now();
    // signals_notnull = signals_notnull.map(signal => applyVoltageFilter(signal as SignalDataSingle, data.signalTypeOptions));
    // console.log("Time taken to apply voltage filter: ", (Date.now() - t1)/1000);
    

    let segid = signals[0]?.segmentationId || -1;
    let recid = signals[0]?.recordingId || -1;

    return {
        signals: signals_notnull,
        requestId: data.requestId,
        signalTypeOptions: data.signalTypeOptions,
        answers: data.answers || [],
        signalNames: signals_notnull,
        segmentLengthInSeconds: originalSegmentDuration,
        segmentationId: segid,
        recordingId: recid,
        segmentations: data.segmentations,
        requests: data.requests,
        start: data.start,
        end: data.end,
    };


}




export async function AddScoringToSession(sessionId:number, scoring:string, requestId:number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${sessionId}/scoring`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({scoring, requestId}),
    });

    if (!response.ok) {
        throw new Error("Error while adding scoring to session");
    }
}

export async function GetAllScoringsForSession(sessionId:number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${sessionId}/scorings`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error("Error while getting scorings for session");
    }
    return await response.json();
    
}

export async function closeSession(sessionId:number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${sessionId}/close`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error("Error while closing session");
    }
    
}

export async function GetHypnogram(sessionId: number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${sessionId}/hypnogram`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });

    if (!response.ok) {
        throw new Error("Error while getting hypnogram");
    }

    return await response.json();
}

export async function GetScoringSessionUserScoringRequestDto(sessionId: number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${sessionId}/userscoringdto`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error("Error while getting user scoring request dto");
    }
    return await response.json();
}

export async function GetScoringSessionOverview(){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/sessionoverview`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error("Error while getting scoring session overview");
    }
    return await response.json();
}
export async function deleteScoringSession(sessionId:number){
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}scoringsession/${sessionId}`;
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });
    if (!response.ok) {
        throw new Error("Error while deleting scoring session");
    }
}