import React, { FC } from 'react';
import { Typography } from '@mui/material';
import './goodjob.css'; // Make sure this file includes the updated CSS

const GoodJobBox: FC = () => (
    <div className="good-job-box">
        <div className="blob">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#023F92" d="M26.3,-21.3C35.6,-9.4,45.5,1.6,44.3,11.4C43,21.2,30.7,29.7,19.2,32.4C7.7,35,-3,31.8,-21.1,28.9C-39.3,25.9,-64.7,23.3,-74.4,9C-84,-5.3,-77.8,-31.2,-62.8,-44.4C-47.7,-57.6,-23.9,-58.2,-7.6,-52.1C8.6,-46,17.1,-33.2,26.3,-21.3Z" transform="translate(125 100) scale(1.25 1.25)  " />
            </svg>
        </div>
        <div className="good-job-box-content">
            <Typography variant="h6" gutterBottom>
                Good job!
            </Typography>
            <Typography variant="body1">
                Keep going!
            </Typography>
        </div>
    </div>
);

export default GoodJobBox;
