import React from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define the types for activity data
type Scoring = {
  id: number;
  scoringLabel: string;
  scoringType: string;
  segmentationId: number;
};

type Segmentation = {
  id: number;
  segmentationTypeId: number;
  segmentationType: string | null;
  startTimestamp: string;
  stopTimestamp: string;
};

type UserScoring = {
  id: number;
  userId: number;
  recordingId: number;
  experimentId: number;
  scoringType: string;
  segmentationId: number;
  segmentation?: Segmentation;
  relatedScorings?: Scoring[];
  correct?: boolean;
  createdAt: string; // Assuming createdAt is a string
};

type Request = {
  id: number;
  strategy: string | null;
  recommendation: string | null;
  userId: number;
  recordingId: number;
  createdAt: string;
  segmentationId: number;
};

type Activity = {
  scorings: Scoring[];
  segmentations: Segmentation[];
  userScorings: UserScoring[];
  request: Request[];
};

type SessionStats = {
  start: Date;
  end: Date;
  duration: number;
  correct: number;
  incorrect: number;
  correctCounts: { [key: string]: number };
  userScorings: UserScoring[];
  correctPercentage: number;
  sleepStagePercentages: { [key: string]: number | string };
};

const formatDate = (date: Date) => date.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });

const ActivityVisualiser: React.FC<{ activity: Activity }> = ({ activity }) => {
  if (!activity) {
    return <>loading</>;
  }

  const { scorings, segmentations, userScorings } = activity;

  // Map scoringType to scoringLabel prefix
  const scoringTypeToLabelPrefix: { [key: string]: string } = {
    rem: 'sleep-rem',
    n1: 'sleep-n1',
    n2: 'sleep-n2',
    n3: 'sleep-n3',
    wake: 'wake'
  };

  // Add segmentation object to userScorings
  const enrichedUserScorings = userScorings.map(userScoring => {
    const segmentation = segmentations.find(seg => seg.id === userScoring.segmentationId);
    return { ...userScoring, segmentation };
  });

  // Add related scorings to userScorings and determine correctness
  enrichedUserScorings.forEach(userScoring => {
    const relatedScorings = scorings.filter(scoring => scoring.segmentationId === userScoring.segmentationId);
    userScoring.relatedScorings = relatedScorings;
    // use userScoring.scoringType in lowercase
    const correctLabel = scoringTypeToLabelPrefix[ userScoring.scoringType.toLocaleLowerCase() ];
    userScoring.correct = relatedScorings.some(scoring => scoring.scoringLabel === correctLabel);
  });

  // Group userScorings into contiguous sessions
  const sortedUserScorings = [...enrichedUserScorings].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  const sessions: { start: Date; end: Date; userScorings: UserScoring[] }[] = [];
  let currentSession: { start: Date; end: Date; userScorings: UserScoring[] } | null = null;

  sortedUserScorings.forEach(userScoring => {
    const createdAt = new Date(userScoring.createdAt);
    if (currentSession) {
      const diff = (createdAt.getTime() - currentSession.end.getTime()) / (1000 * 60); // difference in minutes
      if (diff < 30) {
        currentSession.end = createdAt;
        currentSession.userScorings.push(userScoring);
      } else {
        sessions.push(currentSession);
        currentSession = { start: createdAt, end: createdAt, userScorings: [userScoring] };
      }
    } else {
      currentSession = { start: createdAt, end: createdAt, userScorings: [userScoring] };
    }
  });
  if (currentSession) sessions.push(currentSession);

  // Calculate statistics for each session
  let sessionStats: SessionStats[] = sessions.map(session => {
    const correctCounts: { [key: string]: number } = {
      'sleep-rem': 0,
      'sleep-n1': 0,
      'sleep-n2': 0,
      'sleep-n3': 0,
      'wake': 0
    };
    const totalCounts: { [key: string]: number } = {
      'sleep-rem': 0,
      'sleep-n1': 0,
      'sleep-n2': 0,
      'sleep-n3': 0,
      'wake': 0
    };
    let correct = 0;
    let incorrect = 0;

    session.userScorings.forEach(userScoring => {
      const scoringLabel = scoringTypeToLabelPrefix[userScoring.scoringType];
      totalCounts[scoringLabel]++;
      if (userScoring.correct) {
        correct++;
        correctCounts[scoringLabel]++;
      } else {
        incorrect++;
      }
    });

    const total = correct + incorrect;
    const correctPercentage = total > 0 ? (correct / total) * 100 : 0;
    const sleepStagePercentages = Object.fromEntries(
      Object.entries(totalCounts).map(([stage, count]) =>
        [stage, count > 0 ? (correctCounts[stage] / count) * 100 : '-']
      )
    );

    return {
      start: session.start,
      end: session.end,
      duration: (session.end.getTime() - session.start.getTime()) / (1000 * 60), // duration in minutes
      correct,
      incorrect,
      correctCounts,
      userScorings: session.userScorings,
      correctPercentage,
      sleepStagePercentages
    };
  });

  // Sort sessionStats in descending order based on the start date
  sessionStats = sessionStats.sort((a, b) => b.start.getTime() - a.start.getTime());

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 12, minWidth:'600px' }}>
      <Paper sx={{ padding: 2, margin: 1, width: '80%' }}>
        <Typography variant="h6">Summary</Typography>
        <List>
          <ListItem>
            <ListItemText primary={`Correct: ${enrichedUserScorings.filter(us => us.correct).length}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Incorrect: ${enrichedUserScorings.filter(us => !us.correct).length}`} />
          </ListItem>
        </List>
      </Paper>

      <Paper sx={{ padding: 2, margin: 1, width: '90%' }}>
        <Typography variant="h6">Session Statistics</Typography>
        <Divider sx={{ mb: 2 }} />
        {sessionStats.map((session, index) => (
          <Accordion key={index} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography>{`Session ${index + 1}: ${formatDate(session.start)} - ${formatDate(session.end)}`}</Typography>
                <Typography sx={{ color: 'green' }}>{`Correct: ${session.correct} (${session.correctPercentage.toFixed(2)}%)`}</Typography>
                <Typography sx={{ color: 'blue', mt: 1 }}>Sleep Stage Breakdown:</Typography>
                <Grid container spacing={1} sx={{ mt: 1 }} justifyContent="center">
                  {Object.entries(session.sleepStagePercentages).map(([stage, percentage]) => (
                    <Grid item xs={6} sm={4} md={2} key={stage}>
                      <Paper sx={{ padding: 1, textAlign: 'center', backgroundColor: '#f0f0f0' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {stage}
                        </Typography>
                        <Typography variant="body1">
                          {typeof percentage === 'number' ? `${percentage.toFixed(0)}%` : '-'}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{`Duration: ${session.duration.toFixed(2)} minutes`}</Typography>
              <Typography sx={{ color: 'green' }}>{`Correct: ${session.correct}`}</Typography>
              <Typography sx={{ color: 'red' }}>{`Incorrect: ${session.incorrect}`}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">User Scorings</Typography>
              <List>
                {session.userScorings.map((userScoring: UserScoring) => (
                  <ListItem key={userScoring.id}>
                    <ListItemText
                      primary={`User Scoring ID: ${userScoring.id} - ${userScoring.scoringType}`}
                      secondary={`Recording ID: ${userScoring.recordingId}, ${userScoring.correct ? 'Correct' : 'Incorrect'}`}
                      sx={{ color: userScoring.correct ? 'green' : 'red' }}
                    />
                    {userScoring.segmentation && (
                      <Box sx={{ ml: 2 }}>
                        <Typography>{`Segmentation ID: ${userScoring.segmentation.id}`}</Typography>
                        <Typography>{`Start: ${formatDate(new Date(userScoring.segmentation.startTimestamp))}`}</Typography>
                        <Typography>{`Stop: ${formatDate(new Date(userScoring.segmentation.stopTimestamp))}`}</Typography>
                      </Box>
                    )}
                    <Box sx={{ ml: 2 }}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Related Scorings:</Typography>
                      <List dense>
                        {userScoring.relatedScorings?.map(scoring => (
                          <ListItem key={scoring.id}>
                            <ListItemText primary={`${scoring.scoringLabel} (${scoring.scoringType})`} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Box>
  );
};

export default ActivityVisualiser;
