import React, { useState, useEffect, useCallback } from "react";
import { Box, Container, Typography, Button, Paper, CircularProgress } from "@mui/material";
import ScoringButtons from "../ScoringButtons";
import { AddScoringToSession, GetScoringSessionById, closeSession } from "../../../api/scoringAPI";
import { useParams } from "react-router-dom";
import Keybindings from "../Keybinds";
import SleepChart from "./SleepChart";
import Hypnogram from "./Hypnogram";
import { PrepareRecommendations } from "./shared";
import { PrepareScorings } from "./apiCalls";
import './TutorialScoringSession.css'; // Import the CSS file for animations
import GoodJobBox from "./GoodJobBox";
import { findRequestForScoring, LoadNext } from "./utils";

interface ScoringProps {
    darkMode: boolean;
    userId: number;
}

const TutorialScoringSession: React.FC<ScoringProps> = ({ darkMode, userId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { scoringSessionId = "" } = useParams<{ scoringSessionId: string }>();
    const scoringSessionIdInt = parseInt(scoringSessionId);

    const [answers, setAnswers] = useState<string[]>([]);
    const [signalNames, setSignalNames] = useState<string[]>([]);
    const [brushStartSeconds, setBrushStartSeconds] = useState<number>(0.0);
    const [brushEndSeconds, setBrushEndSeconds] = useState<number>(30.0);
    const [segmentations, setSegmentations] = useState<any[]>([]);
    const [requests, setRequests] = useState<any[]>([]);
    const [sessionStart, setSessionStart] = useState<Date | null>(null);
    const [sessionEnd, setSessionEnd] = useState<Date | null>(null);
    const [signalGroupOptions, setSignalGroupOptions] = useState<any[]>([]);
    const [loadedDurationSeconds, setLoadedDurationSeconds] = useState<number>(0);
    const [sessionLengthInSeconds, setSessionLengthInSeconds] = useState<number>(0);
    const [signals, setSignals] = useState<any[]>([]);
    const [userScorings, setUserScorings] = useState<any[]>([]);
    const [panZoom, setPanZoom] = useState(false);
    const [loadingNextSegment, setLoadingNextSegment] = useState(false);

    // Tutorial state
    const [tutorialStep, setTutorialStep] = useState<number>(0);
    // const [showFinalPopup, setShowFinalPopup] = useState<boolean>(false);
    const [showTutorial, setShowTutorial] = useState<boolean>(true);
    const [showGoodJob, setShowGoodJob] = useState<boolean>(false);
    const [tutorialCompleted, setTutorialCompleted] = useState<boolean>(false);

    // Variables to track if the recommendations have been shown
    const [showHumanRecommendationTutorial, setShowHumanRecommendationTutorial] = useState<boolean>(false);
    const [showAITutorial, setShowAITutorial] = useState<boolean>(false);

    // Variables to track if the recommendations have been shown
    const [aiTutorialShown, setAITutorialShown] = useState<boolean>(false);
    const [humanTutorialShown, setHumanTutorialShown] = useState<boolean>(false);

    const [scoringTutorialCompleted, setScoringTutorialCompleted] = useState<boolean>(false);

    const tutorialSteps = [
        "Welcome to the tutorial! Start by using the 'd' or '→' keys to move one epch forward in the recording.", // 0 a 
        "Good job! Now use the 'a' or '←' key to move one epoch back in the recording.",                           // 1 d
        "Great! Now, use the 'Shift + D' or 'Shift + →' keys to jump five seconds ahead.",                             // 2 s + a
        "Use the 'Shift + A' or 'Shift + ←' keys to jump five seconds back. These controls are also visible next to the scoring buttons!",                                     // 3 s + d
        "Now, press the 'Space' bar to zoom out and see the surrounding epochs.",                                              // 4 space
        "Press 'Space' again to zoom back in.",                                                                      // 5 space
        "You can manipulate the signals by left-clicking and dragging them to change their position on the canvas.", // 6 clicking
        "You can also right-click and drag ← right to increase the signal scale, and left → to decrease the amplitude.",   // 7 clicking
        `If anything goes wrong, use the button in the upper right corner to reset the scale and position of all signals. When you press 'Got it', we will move to the first epoch again, notice how the hypnogram highlights the epoch you are viewing.`, // 8 fixing
        "Now, either use the buttons above the signals to add a scoring to the current segment, or use the numbers in the parenthesis. When you score an epoch, you automatically advance to the next epoch.", // 9 scoring
        "Good job! Notice how the hypnogram on top of the signals is automatically filled in when you score. To change a scoring, select a different answer for the current segment.", // 10 scoring overriden
        "Now you got the hang of this, try scoring the entire session and fill out the hypnogram above. (Hint: try clicking on the hypnogram)", // 11 finished scoring
        "Tutorial complete! You can now explore the session on your own.",                                             // 12 tutorial complete
        "Congratulations! You have finished scoring. You can now close the session using the button next to the hypnogram." // 13 close button tutorial
    ];

    //👨‍🔬 🤖

    const handleCloseSession = async () => {
        await closeSession(scoringSessionIdInt);
        console.log("Session closed");
        window.location.href = "/?tab=2";
    };

    const fetchAndSetData = useCallback(async () => {
        try {
            const data = await GetScoringSessionById(scoringSessionIdInt);
            if (!data) throw new Error("No signals available");

            const sessionLength = (new Date(data.end).getTime() - new Date(data.start).getTime()) / 1000;
            setSessionLengthInSeconds(sessionLength);

            setSignals(data.signals);
            setSignalGroupOptions(data.signalTypeOptions);
            setAnswers(data.answers);
            setSignalNames(data.signals.map((signal: any) => signal.signalName));

            if (!data.signals[0]?.values?.length) throw new Error("No signals available");

            setSegmentations(data.segmentations);
            setRequests(data.requests);
            setSessionStart(new Date(data.start));
            setSessionEnd(new Date(data.end));
            setLoadedDurationSeconds(data.signals[0].values.length / data.signals[0].samplingFrequency);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error as Error);
        }
    }, [scoringSessionIdInt]);

    useEffect(() => {
        fetchAndSetData();
    }, [scoringSessionIdInt, fetchAndSetData]);

    const fetchAndSetScorings = useCallback(async () => {
        if (!requests.length || !segmentations.length) return;

        const data = await PrepareScorings(scoringSessionIdInt, requests, segmentations);
        setUserScorings(data);
    }, [requests, scoringSessionIdInt, segmentations]);

    useEffect(() => {
        fetchAndSetScorings();
    }, [fetchAndSetScorings]);

    const alignToInterval = (value: number) => Math.floor(value / 30) * 30;

    const moveBrushToLeftSegment = useCallback(() => {
        const newStart = alignToInterval(brushStartSeconds);
        const newEnd = alignToInterval(brushEndSeconds);

        if (Math.abs(newStart - brushStartSeconds) <= 1) {
            setBrushStartSeconds(brushStartSeconds - 30);
            setBrushEndSeconds(brushEndSeconds - 30);
        } else {
            setBrushStartSeconds(newStart);
            setBrushEndSeconds(newEnd);
        }

        if (tutorialStep === 1) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(2);
            }, 1000);
        }
    }, [brushStartSeconds, brushEndSeconds, tutorialStep]);

    const moveBrushToRightSegment = useCallback(() => {
        const newStart = alignToInterval(brushStartSeconds + 30);
        const newEnd = alignToInterval(brushEndSeconds + 30);

        if (newEnd > loadedDurationSeconds) {
            console.log("Reached the end of the loaded data");
        } else {
            setBrushStartSeconds(newStart);
            setBrushEndSeconds(newEnd);
        }

        if (tutorialStep === 0) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(1);
            }, 1000);
        }
    }, [brushStartSeconds, brushEndSeconds, loadedDurationSeconds, tutorialStep]);


    const handleShiftD = useCallback(() => {
        if (brushEndSeconds >= loadedDurationSeconds - 0.4) {
            console.log("Can't go further");
        } else {
            setBrushStartSeconds(prevBrushStart => prevBrushStart + 5);
            setBrushEndSeconds(prevBrushEnd => prevBrushEnd + 5);
        }

        if (tutorialStep === 2) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(3);
            }, 1000);
        }
    }, [brushEndSeconds, loadedDurationSeconds, tutorialStep]);

    const handleShiftA = useCallback(() => {
        if (brushStartSeconds <= 0.5) {
            console.log("Can't go further");
        } else {
            setBrushStartSeconds(prevBrushStart => prevBrushStart - 5);
            setBrushEndSeconds(prevBrushEnd => prevBrushEnd - 5);
        }

        if (tutorialStep === 3) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(4);
            }, 1000);
        }
    }, [brushStartSeconds,  tutorialStep]);


    const handleA = useCallback(() => {
        if (scoringSessionId === "") return;

        if (brushStartSeconds <= 0.5) {
            setBrushStartSeconds(0.1);
            setBrushEndSeconds(30.0);
        } else {
            moveBrushToLeftSegment();
        }
    }, [brushStartSeconds, moveBrushToLeftSegment, scoringSessionId]);

    const handleD = useCallback(() => {
        if (scoringSessionId === "") return;

        if (brushEndSeconds >= loadedDurationSeconds - 0.5) {
            console.log("Reached the end of the loaded data");
        } else {
            moveBrushToRightSegment();
        }
    }, [brushEndSeconds, loadedDurationSeconds, moveBrushToRightSegment, scoringSessionId]);



    const handleSpace = useCallback(() => {
        setBrushStartSeconds((prevBrushStartSeconds) => {
            const middle = (brushEndSeconds + prevBrushStartSeconds) / 2;
            let newBrushStart, newBrushEnd;

            if (panZoom) {
                newBrushStart = Math.max(15, Math.min(loadedDurationSeconds - 15, middle)) - 15;
                newBrushEnd = newBrushStart + 30;
            } else {
                newBrushStart = Math.max(45, Math.min(loadedDurationSeconds - 45, middle)) - 45;
                newBrushEnd = newBrushStart + 90;
            }

            setBrushEndSeconds(newBrushEnd);
            return newBrushStart;
        });
        setPanZoom(!panZoom);

        if (tutorialStep === 4) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(5);
            }, 1000);
        } else if (tutorialStep === 5) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(6);
            }, 1000);
        }
    }, [brushEndSeconds, loadedDurationSeconds, panZoom, tutorialStep]);

    useEffect(() => {
        const keydownHandler = (event: any) => {
            if (tutorialCompleted) {
                if ((event.key === 'a' || event.key === 'ArrowLeft') && !event.shiftKey) handleA();
                if ((event.key === 'd' || event.key === 'ArrowRight') && !event.shiftKey) handleD();
                if ((event.key === 'A' || event.key === 'ArrowLeft') && event.shiftKey) handleShiftA();
                if ((event.key === 'D' || event.key === 'ArrowRight') && event.shiftKey) handleShiftD();
                if (event.key === ' ') {
                    event.preventDefault();
                    handleSpace();
                }
            } else {
                if ((event.key === 'a' && !event.shiftKey) || (event.key === 'ArrowLeft' && !event.shiftKey)) {
                    if (tutorialStep === 1) handleA();
                }
                if ((event.key === 'd' && !event.shiftKey) || (event.key === 'ArrowRight' && !event.shiftKey)) {
                    if (tutorialStep === 0) handleD();
                }
                if ((event.key === 'A' || event.key === 'a' || event.key === 'ArrowLeft') && event.shiftKey) {
                    if (tutorialStep === 3) handleShiftA();
                }
                if ((event.key === 'D' || event.key === 'd' || event.key === "ArrowRight") && event.shiftKey) {
                    if (tutorialStep === 2) handleShiftD();
                }
                if (event.key === ' ') {
                    event.preventDefault();
                    if (tutorialStep === 4 || tutorialStep === 5) handleSpace();
                }
            }
        };

        window.addEventListener('keydown', keydownHandler);

        return () => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, [handleShiftA, handleShiftD, handleA, handleD, handleSpace, tutorialStep, tutorialCompleted]);

    const handleAnswerSelected = async (answer: string) => {
        if (scoringSessionId === "") return;

        if(sessionStart === null) return;

        if (brushEndSeconds - brushStartSeconds > 30) return;

        const [segmentation, request] = findRequestForScoring(brushStartSeconds, brushEndSeconds, requests, segmentations, sessionStart);

        await AddScoringToSession(scoringSessionIdInt, answer, request.id);

        const userScoring = { scoring: answer, segmentation, request };
        const newScorings = userScorings.filter((scoring: any) => scoring.segmentation.id !== segmentation.id);

        setUserScorings([...newScorings, userScoring]);

        console.log("Going to next segment");
        
        if (brushEndSeconds + 30 <= loadedDurationSeconds) {
            // find the next segment
            const nextSegmentation = segmentations.find(seg => seg.startTimestamp === segmentation.stopTimestamp);
            if (nextSegmentation) {
                const start = new Date(nextSegmentation.startTimestamp).getTime() - sessionStart!.getTime();
                const end = new Date(nextSegmentation.stopTimestamp).getTime() - sessionStart!.getTime();
                setBrushStartSeconds(start / 1000);
                setBrushEndSeconds(end / 1000);
            } else {
                console.log("Did not find next segment");
            }
        } else {
            console.log("No more segments to score");   
        }


        

        if ([9, 10].includes(tutorialStep)) {
            setShowGoodJob(true);
            setTimeout(() => {
                setShowGoodJob(false);
                setTutorialStep(tutorialStep + 1);
                if (tutorialStep === 10) {
                    setShowTutorial(true);
                }
            }, 1000);
        }

        if (newScorings.length === segmentations.filter((seg: any) => seg.segmentationTypeId === 1).length - 1) {
            // setShowFinalPopup(true);
            setTutorialStep(13); // Trigger the final tutorial step for the close button
        }
    };

    const handleGotIt = () => {
        setShowGoodJob(true);
        if(tutorialStep === 8)  {
            setBrushStartSeconds(0);
            setBrushEndSeconds(30);
        }
        setTimeout(() => {
            setShowGoodJob(false);
            setTutorialStep(tutorialStep + 1);
            if (tutorialStep === 11) {
                setShowTutorial(false);
                setTutorialCompleted(true);
                setScoringTutorialCompleted(true);
            }
        }, 1000);
    };

    const handleCloseRecommendationTutorial = () => {
        setShowHumanRecommendationTutorial(false);
        setShowAITutorial(false);
    };

    const isNotNull = (annotation: any): annotation is any => annotation !== null;

    const annotations = userScorings.map((scoring: any) => {
        if (!scoring.segmentation) return null;
        let start = new Date(scoring.segmentation.startTimestamp).getTime() - sessionStart!.getTime();
        let end = new Date(scoring.segmentation.stopTimestamp).getTime() - sessionStart!.getTime();
        start = start / 1000;
        end = end / 1000;
        const duration = end - start;
        return { start, duration, annotation: scoring.scoring };
    }).filter(isNotNull);

    const recommendations = requests.filter((request: any) => request.recommendation !== "").map(r => PrepareRecommendations(r, segmentations, annotations, sessionStart));

    const loadNextSegmentation = useCallback(async () => {
        if (loadingNextSegment || loadedDurationSeconds >= sessionLengthInSeconds) return;

        setLoadingNextSegment(true);

        try {
            
            const [updatedSignals, newDuration] = await LoadNext(segmentations, signals, loadedDurationSeconds, sessionStart, scoringSessionIdInt);

            setSignals(updatedSignals);
            setLoadedDurationSeconds(newDuration);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingNextSegment(false);
        }
    }, [loadingNextSegment, loadedDurationSeconds, segmentations, sessionLengthInSeconds, signals, scoringSessionIdInt, sessionStart]);

    useEffect(() => {
        if (!loadingNextSegment && loadedDurationSeconds < sessionLengthInSeconds) loadNextSegmentation();
    }, [loadedDurationSeconds, loadNextSegmentation, loadingNextSegment, sessionLengthInSeconds]);

    // a function that we pass to the hypnogram, that tells us the ID of the segmentation to zoom to
    const handleZoomToSegmentation = (segmentationId: number) => {
        const segmentation = segmentations.find(seg => seg.id === segmentationId);
        if (!segmentation) return;

        const start = new Date(segmentation.startTimestamp).getTime() - sessionStart!.getTime();
        const end = new Date(segmentation.stopTimestamp).getTime() - sessionStart!.getTime();
        setBrushStartSeconds(start / 1000);
        setBrushEndSeconds(end / 1000);
    };

    useEffect(() => {
        if (showTutorial) {
            
            // Handle the tutorial logic for the third epoch specifically
            if (tutorialStep === 10 && Math.floor(brushStartSeconds / 30) === 2) {
                setShowGoodJob(true);
                setTimeout(() => {
                    setShowGoodJob(false);
                    setTutorialCompleted(true);
                    setScoringTutorialCompleted(true);
                }, 1000);
            }
        }
    }, [brushStartSeconds, showTutorial, tutorialStep]);

    useEffect(() => {
        const checkAndShowRecommendations = () => {
            if (!scoringTutorialCompleted) return;

            const isClose = (a: number, b: number) => Math.abs(a - b) < 0.1;

            const humanRecommendation = recommendations.some(r => 
                (r.annotation.includes('Human') || r.annotation.includes('👨‍🔬')) && 
                isClose(r.start, brushStartSeconds) && 
                isClose(r.start + r.duration, brushEndSeconds)
            );

            const aiRecommendation = recommendations.some(r => 
                (r.annotation.includes('AI') || r.annotation.includes('🤖')) && 
                isClose(r.start, brushStartSeconds) && 
                isClose(r.start + r.duration, brushEndSeconds)
            );

            if (humanRecommendation && !humanTutorialShown) {
                setHumanTutorialShown(true);
                setShowHumanRecommendationTutorial(true);
            }

            if (aiRecommendation && !aiTutorialShown) {
                setAITutorialShown(true);
                setShowAITutorial(true);
            }
        };

        checkAndShowRecommendations();
    }, [brushStartSeconds, brushEndSeconds, recommendations, scoringTutorialCompleted, humanTutorialShown, aiTutorialShown]);

    if (isLoading) {
        return (
            <Container component="main" maxWidth="xl">
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container component="main" maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" pt={8}>
                    <Typography component="h1" variant="h4" color="error">
                        Error: {error.message}
                    </Typography>
                </Box>
            </Container>
        );
    }

    const showCloseButton = userScorings.length === segmentations.filter((seg: any) => seg.segmentationTypeId === 1).length;

    if (!signalNames.length) {
        return (
            <Container component="main" maxWidth="xl" className="scoring-container">
                <Box display="flex" flexDirection="column" alignItems="center" pt={8}>
                    <Typography component="h1" variant="h5">
                        No data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    const trimmedSignals = signals.map(signal => {
        const startIdx = Math.max(0, Math.floor(brushStartSeconds * signal.samplingFrequency));
        const endIdx = Math.min(signal.values.length, Math.floor(brushEndSeconds * signal.samplingFrequency));
        const values = signal.values.slice(startIdx, endIdx);
        return { ...signal, values };
    });

    const scoringSessionDuration = (sessionEnd!.getTime() - sessionStart!.getTime()) / 1000;
    
    return (
        <>
            <Keybindings />
            {showTutorial && (
                <Paper className={`tutorial-box ${tutorialStep >= 0 && tutorialStep < tutorialSteps.length ? "visible" : ""}`}>
                    <Typography variant="body1">
                        {tutorialSteps[tutorialStep]}
                    </Typography>
                    { ((tutorialStep >= 6 && tutorialStep <= 8) || (tutorialStep === 11) || (tutorialStep === 12)) && (
                        <Button variant="contained" color="primary" onClick={handleGotIt}>
                            Got it!
                        </Button>
                    )}
                </Paper>
            )}
            {showGoodJob && (
                <GoodJobBox />
            )}
            {showHumanRecommendationTutorial && (
                <Paper className="tutorial-box visible">
                    <Typography variant="body1">
                        Here is a recommendation from a human sleep technologist, when you score this epoch the recommendation will move into the corners of the epoch, a recommendation from an AI sleep staging software will have a robot emoji instead of a human emoji.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleCloseRecommendationTutorial}>
                        Got it!
                    </Button>
                </Paper>
            )}
            {showAITutorial && (
                <Paper className="tutorial-box visible">
                    <Typography variant="body1">
                        Here is a recommendation from an AI sleep staging software, when you score this epoch the recommendation will move into the corners of the epoch, a recommendation from a human sleep technologist will have a human emoji instead of a robot emoji.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleCloseRecommendationTutorial}>
                        Got it!
                    </Button>
                </Paper>
            )}
            <ScoringButtons
                answers={answers}
                onAnswerSelected={handleAnswerSelected}
                onSkip={() => window.location.reload()}
                mlRecommendation={null}
                strategy={null}
                disabled={!tutorialCompleted && (tutorialStep < 9 || tutorialStep > 10)}
                moveEpochLeft={handleShiftA}
                moveEpochRight={handleShiftD}
                nudgeLeft={handleA}
                nudgeRight={handleD}
            />

            <Box display="flex" flexDirection="row" alignItems="center">
                {sessionStart && (
                    <Box sx={{ flexBasis: showCloseButton ? "80%" : "100%", transition: "flex-basis 0.3s" }}>
                        <Hypnogram brushEnd={brushEndSeconds} brushStart={brushStartSeconds} startTime={sessionStart} durationSeconds={scoringSessionDuration} scorings={userScorings} loadedSeconds={loadedDurationSeconds} segmentations={segmentations} onHypnogramClick={handleZoomToSegmentation} />
                    </Box>
                )}
                {showCloseButton && (
                    <Box flexBasis="20%" display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCloseSession}
                            style={{ margin: "2px", height: "100%" }}
                        >
                            Close session
                        </Button>
                    </Box>
                )}
            </Box>

            <SleepChart experimentId={scoringSessionIdInt}
                signals={trimmedSignals}
                signalGroupOptions={signalGroupOptions}
                brushStartSeconds={brushStartSeconds}
                brushEndSeconds={brushEndSeconds}
                originalSegmentStart={0}
                originalSegmentEnd={Infinity}
                mlRecommendation={null}
                strategy={null}
                annotations={annotations}
                recommendations={recommendations}
                darkMode={darkMode}
                sessionStart={sessionStart} />
        </>
    );
};

export default TutorialScoringSession;
