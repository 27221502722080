
export async function authenticateUser(email: string, password: string) {
  const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/Login`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (!response.ok) {
    throw new Error("Error while authenticating user");
  }

  const data = await response.text();

  // console.log('login data', data);

  return data;
}

export async function VerifyUser(token: string) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/Verify?tempToken=${encodeURIComponent(token)}`;
    
    const response = await fetch(url, {
        method: "POST",
    });
    
    if (!response.ok) {
        throw new Error("Error while authenticating user");
    }
    
    const data = await response.text();
    
    // console.log('login data', data);
    
    return data;
        
}

export async function RequestPasswordChange(email: string) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/RequestPasswordChange?email=${encodeURIComponent(email)}`;
    
    const response = await fetch(url, {
        method: "POST",
    });
    
    if (!response.ok) {
        throw new Error("Error while authenticating user");
    }
    
    const data = await response.text();
    
    // console.log('login data', data);
    
    return data;
};

export async function SendPasswordChange(token: string, password: string) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/ResetPassword?token=${encodeURIComponent(token)}&password=${encodeURIComponent(password)}`;
    
    const response = await fetch(url, {
        method: "POST"
    });
    
    if (!response.ok) {
        throw new Error("Error while authenticating user");
    }
    
    const data = await response.text();
    
    // console.log('login data', data);
    
    return data;

}

export async function getCountries(){
    // https://restcountries.com/v3.1/all?fields=name
    const url = `https://restcountries.com/v3.1/all?fields=name`;
    const response = await fetch(url, {
        method: "GET"
    });
    return await response.json();
}

export async function getUserIdentity() {
  const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/Identity`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("wat");
    }

    const data = await response.json();

    return data;
}

export async function getGoogleUserInfo(token: string) {
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("wat");
    }

    const data = await response.json();

    return data;
}


export async function GoogleRegister(token: string, name:string, country:string, isAccredited:boolean, accreditationLocation:string) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/GoogleRegister`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token,
            name,
            country,
            isAccredited,
            accreditationLocation
        })
    });

    if (!response.ok) {
        throw new Error(`${response.status}`);
    }

    const data = await response.json();

    return data;
}

export async function authenticateGoogle(token: string) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/GoogleLogin`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token
        })
    });

    if (!response.ok) {
        throw new Error(`${response.status}`);
    }

    const data = await response.json();

    return data;
}

export async function registerUser(email: string, password: string, name: string, country: string, isAccredited: boolean, accreditationLocation: string) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/Register`; //?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&userName=${encodeURIComponent(username)}`;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            password,
            name,
            country,
            isAccredited,
            accreditationLocation
        }),
    });

    if (!response.ok) {
        throw new Error("Registration failed");
    }

    const data = await response.text();
    // console.log(data);
    return data;
}

export async function GetUserActivity() {
    
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}dashboard/activity`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("wat");
    }

    const data = await response.json();

    return data;
}

export async function GetUserActivityForOtherUser(userId:number) {
    
    const token = localStorage.getItem("user");
    const url = `${process.env.REACT_APP_API_BASE_URL}dashboard/activity/${userId}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error("wat");
    }

    const data = await response.json();

    return data;
}
