import { Box, Button, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CreateTutorialScoringSession, GetScoringSessionsForLocalUser } from '../../../api/scoringAPI';

interface Props {
    // Define your component props here
}

const Tutorials: React.FC<Props> = () => {
    const [existingSessions, setExistingSessions] = useState([]);
    const [closedSessions, setClosedSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadScoringSessions();
    }, []);

    const loadScoringSessions = () => {
        GetScoringSessionsForLocalUser().then(data => {
            // filter out everything that does not have a experimentName that starts with "Tutorial"
            data = data.filter((session:any) => session.experimentName.startsWith("TUTORIAL"));
            let closed = data.filter((session:any) => session.closed);
            let open = data.filter((session:any) => !session.closed);
            setExistingSessions(open);
            setClosedSessions(closed);
        }).catch(err => {
            console.log(err);
        });
    };

    const handleCreateSession = () => {
        if (existingSessions.length > 0 || isLoading) {
            return; // Do not create a new session if there's an open session or if a request is loading
        }
        let oldSessions = existingSessions.filter((session:any) => session.experimentName.startsWith("TUTORIAL")) as any[];
        console.log(oldSessions);
        
        setIsLoading(true);
        CreateTutorialScoringSession().then((data:any) => {
            let newId = data.newId;
            // navigate to the new session
            window.location.href = `/tutorial/${newId}`;
        }).catch(err => {
            console.log(err);
        });
    };

    return ( 
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                <Typography variant="h4">Create Tutorial Session</Typography>
                
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateSession}
                    disabled={existingSessions.length > 0 || isLoading} // Disable button if there's an open session or request is loading
                >
                    {isLoading ? 'Creating...' : 'Create Tutorial Session'}
                </Button>
                
            </Box>

            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h5">Open Sessions</Typography>
                <List>
                    {existingSessions.length === 0 && (<Typography>No ongoing tutorial.</Typography>) }
                    {existingSessions.map((session:any) => (
                        <ListItem key={session.id}>
                            <ListItemText
                                primary={
                                    <Link href={`/tutorial/${session.id}`} underline="hover">
                                        {session.experimentName}
                                    </Link>
                                }
                                secondary={`Start: ${new Date(session.start).toLocaleString()} | End: ${new Date(session.end).toLocaleString()}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Box sx={{ mt: 4, width: '100%' }}>
                {closedSessions.length > 0 && (
                    <Typography variant="h5">You have finished the tutorial. You can either go and create new scoring sessions, or take the tutorial again. </Typography>
                )}
            </Box>
        </>
    );
};

export default Tutorials;
