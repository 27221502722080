import React, { useState, useEffect } from 'react';
import { Box, Paper, Avatar, Typography } from '@mui/material';
import { GetUserActivityForOtherUser, getUserIdentity } from '../../api/authAPI';
import ActivityVisualiser from '../Index/Profile/ActivityVisualiser';
import { useParams } from 'react-router';
import { GetUserById } from '../../api/userAPI';

interface User {
    isAdministrator: boolean;
    userName: string | null;
    email: string;
    id: number;
    name: string;
    country: string;
    lastLogin: string | null;
}

const UserProfile: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [activity, setActivity] = useState<any | null>(null);

    let { userId = "" } = useParams<{ userId: string }>();
    let id = parseInt(userId);

    useEffect(() => {
        getUserIdentity().then(data => {
            setUser(data);
        }).catch(err => {
            console.log("I am going to navigate to /");
            window.location.href = "/";
        });
    }, []);

    useEffect(() => {
        GetUserActivityForOtherUser(id).then(data => {
            console.log(data);
            setActivity(data);
        }).catch(err => {
            console.log(err);
        });
    }, [id]);

    useEffect(() => {
        GetUserById(id).then(data => {
            setUser(data);
        }).catch(err => {
            console.log(err);
        });
    }, [id]);

    if (!user) {
        return <div>loading</div>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
            <Avatar sx={{ width: 100, height: 100, mb: 2 }} />
            <Paper sx={{ p: 2, width: '100%', maxWidth: 500 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    {user.name || "No Name"}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 1 }}>
                    {user.email}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    {user.isAdministrator ? 'Administrator' : 'User'}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    Country: {user.country || "Not specified"}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    Last Login: {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "Never"}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    User ID: {user.id}
                </Typography>
            </Paper>

            <ActivityVisualiser activity={activity} />
        </Box>
    );
};

export default UserProfile;
