import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import themeScheme from './ThemeScheme.json';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Root: React.FC = () => {
    
  // load dark mode from local storage
    const darkModeFromLocalStorage = localStorage.getItem('darkMode');
    const darkModeInitial = darkModeFromLocalStorage ? darkModeFromLocalStorage === 'true' : false;

  const [darkMode, setDarkMode] = useState(darkModeInitial);

    const setDarkModeAndStore = (value: boolean) => {
        setDarkMode(value);
        localStorage.setItem('darkMode', value.toString());
    }

    const modeColors = darkMode ? themeScheme.dark : themeScheme.light;

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            background: {
            default: modeColors.background,
            },
            primary: {
            main: modeColors.primary,
            },
            secondary: {
            main: modeColors.secondary,
            },
            text: {
            primary: modeColors.onSurface,
            secondary: modeColors.onSurfaceVariant,
            },
        },
    });

    return (
    <GoogleOAuthProvider clientId='948022875242-1rttb19mvs630ceisq6lnp191ec2jlap.apps.googleusercontent.com'>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App darkMode={darkMode} setDarkMode={setDarkModeAndStore} />
        </ThemeProvider>
    </GoogleOAuthProvider>
    );
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('Cannot find root container');
}
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
