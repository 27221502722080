import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UserProfile from './Profile/Profile';
// import ScoringTestIndex from '../ScoringTest/ScoringTestIndex';
// import ScoringSessions from './ScoringSessions/ScoringSessions';
import Tutorials from './Tutorials/Tutorials';
import ExperimentalSetupSessions from './ScoringSessions/ExperimentalSetupSessions';
import { getUserIdentity } from '../../api/authAPI';
import { User } from '../User/types';
import TrainingSessions from './ScoringSessions/TrainingSessions';
import CookieDisclosure from './CookieDisclosure';
// import ScoringSessions from './ScoringSessions/ScoringSessions';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {/* Changed Typography to div */}
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const Index: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        const url = new URL(window.location.href);
        url.searchParams.set('tab', newValue.toString());
        window.history.pushState({}, '', url.toString());
    };
    
    const [user, setUser] = useState<User | null>(null);
    // const [activity, setActivity] = useState<any | null>(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tabIndex = parseInt(urlParams.get('tab') || '0', 10);
        setValue(tabIndex);
    
        getUserIdentity().then(data => {
            setUser(data);
        }).catch(err => {
            console.log("I am going to navigate to /");
            window.location.href = "/";
        });
    }, []);
    
    


    let emaail_training_whitelist = ["b@spock.is", "salanitro.matthew@gmail.com", "anjaseiger@googlemail.com"];

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <CookieDisclosure />
            <Box sx={{ width: '250px', borderRight: 1, borderColor: 'divider' }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                >
                    <Tab label="Profile" {...a11yProps(0)} />
                    {/* <Tab label="Scoring Test" {...a11yProps(1)} /> */}
                    <Tab label="Tutorial" {...a11yProps(1)} />
                    <Tab label="ScoreCraft" {...a11yProps(2)} />

                    {
                        emaail_training_whitelist.includes(user?.email as string) &&
                        <Tab label="Training Sessions" {...a11yProps(3)} />
                    }


                    {/* <Tab label="Scoring Sessions" {...a11yProps(3)} /> */}
                    {/* Add more tabs here if needed */}
                </Tabs>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <TabPanel value={value} index={0}>
                    <UserProfile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Tutorials />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                    <ExperimentList />
                </TabPanel> */}

                <TabPanel value={value} index={2}>
                    <ExperimentalSetupSessions />
                </TabPanel>


                {
                    emaail_training_whitelist.includes(user?.email as string) &&
                    <TabPanel value={value} index={3}>
                        <TrainingSessions />
                    </TabPanel>
                }

                {/* <TabPanel value={value} index={3}>
                    <ScoringSessions />
                </TabPanel> */}
                
                {/* Add more TabPanels here corresponding to each tab */}
            </Box>
        </Box>
    );
};

export default Index;
