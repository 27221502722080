import { Box } from "@mui/material";
import React from "react";

const KeyboardShortcutKey: React.FC<{keyName: string}> = ({ keyName }) => (
    <Box sx={{
        display: 'inline-block',
        minWidth: '24px',
        height: '24px',
        margin: '0 5px',
        border: '1px solid #333',
        borderRadius: '3px',
        textAlign: 'center',
        lineHeight: '24px',
        backgroundColor: '#f7f7f7',
        boxShadow: 'inset 0 1px 0 #fff',
        fontWeight: 'bold',
        fontSize: '0.825rem',
    }}>
        {keyName}
    </Box>
);

export default KeyboardShortcutKey;

