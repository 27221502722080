import React from 'react';
import { IconButton, Popover, Typography, List, ListItem, Box } from "@mui/material";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardShortcutKey from './KeyboardShortcutKey';

// const KeyboardShortcutKey: React.FC<{keyName: string}> = ({ keyName }) => (
//     <Box sx={{
//         display: 'inline-block',
//         minWidth: '24px',
//         height: '24px',
//         margin: '0 5px',
//         border: '1px solid #333',
//         borderRadius: '3px',
//         textAlign: 'center',
//         lineHeight: '24px',
//         backgroundColor: '#f7f7f7',
//         boxShadow: 'inset 0 1px 0 #fff',
//         fontWeight: 'bold',
//         fontSize: '0.875rem',
//     }}>
//         {keyName}
//     </Box>
// );

const KeyboardShortcuts: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'keyboard-shortcuts-popover' : undefined;

    const shortcuts = [
        // TODO add left and right arrow into this.
        { key: 'Shift + a', action: 'Load an earlier epoch.' },
        { key: 'Shift + d', action: 'Load a later epoch.' },
        { key: 'a', action: 'Move to left.' },
        { key: 'd', action: 'Move to the right.' },
        { key: 'Shift + Space', action: 'Toggle between whole signal and original segment.' },
        // { key: 'Space', action:'Move to the active epoch.'}
        // Add more shortcuts here
    ];

    const renderKeys = (keyString: string) => {
        return keyString.split(' + ').map((key, index, array) => (
            <React.Fragment key={index}>
                <KeyboardShortcutKey keyName={key} />
                {index < array.length - 1 && <span>+</span>}
            </React.Fragment>
        ));
    };

    return (
        <div style={{position: 'absolute'}}>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <KeyboardIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography>Keyboard Shortcuts</Typography>
                    <List dense>
                        {shortcuts.map((shortcut, index) => (
                            <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                {renderKeys(shortcut.key)}
                                {shortcut.action}
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
        </div>
    );
}

export default KeyboardShortcuts;