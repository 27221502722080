import { Container, Typography, Paper } from '@mui/material';
import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container component="main" style={{ 
            background: 'linear-gradient(to right, #3f2b96, #a8c0ff)',
            padding: 0, maxWidth: '100%',
            margin: 0, overflow: 'auto',
        }}>
            <Paper style={{
                margin: '4rem auto',
                padding: '2rem',
                backgroundColor: '#f4f4f8',
                borderRadius: '15px',
                width: '80%',
                maxWidth: '800px',
                boxSizing: 'border-box'
            }}>
                <Typography variant="h4" style={{ color: '#3f2b96', marginBottom: '1rem' }}>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" style={{ color: '#333', lineHeight: '1.6' }}>
                    <strong>Last updated: August 2, 2024</strong><br/><br/>
                    Welcome to MicroNyx.is, a research tool designed for studying sleep patterns and scorer habits. Please note that MicroNyx.is is not a medically certified service and should not be used for medical diagnosis, treatment, or as a substitute for professional healthcare or clinical tools.<br/><br/>

                    <strong>1. Data Collection and Use</strong><br/>
                    We collect personal data through account registration, including your email and username. We store sleep recordings for research purposes, focusing on understanding sleep scorer habits and improving machine learning models for automated scoring and assisting sleep technologists. Remember, our platform is for research and not for medical use.<br/><br/>
                    We may access, use, store, and/or share Google user data solely for the purpose of enhancing user experience and functionality within the app. This includes authentication via Google OAuth and access to Google user profile information (such as email and name). We do not use this data for any other purposes.<br/><br/>

                    <strong>2. Data Storage</strong><br/>
                    No payment information is stored. All medical data are anonymized, and we do not store passwords in plaintext. We are committed to protecting your data's confidentiality and integrity. Google user data accessed via OAuth is stored securely and in compliance with Google's Limited use requirements.<br/><br/>

                    <strong>3. Data Sharing</strong><br/>
                    We do not share any personal or medical data, including Google user data, with third parties.<br/><br/>

                    <strong>4. User Rights</strong><br/>
                    You have an irrevocable right to remove all your personal information and any sleep or medical data you have uploaded to the platform at any time. This includes the right to revoke our access to your Google user data.<br/><br/>

                    <strong>5. Data Security</strong><br/>
                    We employ appropriate security measures to prevent unauthorized access, disclosure, alteration, or destruction of your data, including Google user data.<br/><br/>

                    <strong>6. In-Product Privacy Notifications</strong><br/>
                    Privacy notifications are prominently displayed within the app interface to ensure users can easily find this information.<br/><br/>

                    <strong>7. Contact Us</strong><br/>
                    For any privacy concerns or inquiries, please contact us at b@spock.is.<br/><br/>

                    Thank you for participating in our research at MicroNyx.is.
                </Typography>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
