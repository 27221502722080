import { UserObject } from "../components/User/types";

export async function getAllUsers() {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/Users`;
    const token = localStorage.getItem("user");
  
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
      method: "GET",
    });
  
    if (!response.ok) {
      throw new Error("Error while fetching users");
    }
  
    const data = await response.json();
  
    return data;
  }


  export const createUser = async (user: UserObject) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = 'Authentication/Register';
    const url = `${baseUrl}${endpoint}`;
    const token = localStorage.getItem("user");


    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            console.log('Error:', response.statusText);
            return false;
        }

        const data = await response;
        return data;

    } catch (error) {
        console.log('error', error);
        return false;
    }
};


// export const editUser = async (user: UserObject) => {
//     const baseUrl = process.env.REACT_APP_API_BASE_URL;
//     const endpoint = 'Authentication/Register';
//     const url = `${baseUrl}${endpoint}`;
//     const token = localStorage.getItem("user");


//     try {
//         const response = await fetch(url, {
//             method: "POST",
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(user),
//         });

//         if (!response.ok) {
//             console.log('Error:', response.statusText);
//             return false;
//         }

//         const data = await response;
//         return data;

//     } catch (error) {
//         console.log('error', error);
//         return false;
//     }
// };


  export const deleteUser = async (userId: number) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/Users/${userId}`;
    const token = localStorage.getItem("user");
  
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  
    if (!response.ok) {
      let errorMessage = "Error while deleting dataset";
      try {
        // Try to parse the response body as JSON to get the server's error message
        const responseBody = await response.json();
        if (responseBody.error) {
          errorMessage += ": " + responseBody.error;
        }
      } catch {
        // If the response body could not be parsed as JSON, ignore and use the default error message
      }
      throw new Error(errorMessage);
    }
  };

export function GetUserById(userId: number) {
    const url = `${process.env.REACT_APP_API_BASE_URL}Authentication/users/id/${userId}`;
    const token = localStorage.getItem("user");

    return fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (!response.ok) {
            throw new Error("Error while fetching user");
        }
        return response.json();
    });
}