import { GetAllScoringsForSession } from "../../../api/scoringAPI";



export async function PrepareScorings(experimentIdInt: number, requests:any[], segmentations:any[]) : Promise<any> {
    
    if (requests.length === 0 || segmentations.length === 0) {
        return;
    }

    const data = await GetAllScoringsForSession(experimentIdInt);

    for (let scoring of data) {
        let request = requests.find((req: any) => req.id === scoring.scoringSessionRequestId);
        scoring.request = request;
    }

    for (let scoring of data) {
        if (scoring.request === undefined) {
            continue;
        }
        let segmentation = segmentations.find((seg: any) => seg.id === scoring.request.segmentationId);
        scoring.segmentation = segmentation;
    }
    
    
    return data;
}