import { Container, Typography, Paper } from '@mui/material';
import React from 'react';

const TOS: React.FC = () => {
    return (
        <Container component="main" style={{ 
            background: 'linear-gradient(to right, #3f2b96, #a8c0ff)',
            padding: 0, maxWidth: '100%',
            margin: 0, overflow: 'auto',
            
        }}>
            <Paper style={{
                margin: '4rem auto',
                padding: '2rem',
                backgroundColor: '#f4f4f8',
                borderRadius: '15px',
                width: '80%',
                maxWidth: '800px',
                boxSizing: 'border-box'
            }}>
                <Typography variant="h4" style={{ color: '#3f2b96', marginBottom: '1rem' }}>
                    Terms of Service
                </Typography>
                <Typography variant="body1" style={{ color: '#333', lineHeight: '1.6' }}>
                    Welcome to MicroNyx.is. By accessing and using this website, you acknowledge that MicroNyx.is is a research tool and not a medical service or a medically certified service. It should not be used for medical diagnosis, treatment, or as a substitute for professional healthcare advice.
                </Typography>
                    <p>Please read the following terms and conditions carefully before using our website:</p>
                    <ul>
                        <li><strong>User Responsibilities:</strong> Users must keep their account credentials secure and not share their account with anyone. Users are prohibited from uploading any personally identifiable data to the platform.</li>
                        <li><strong>Intellectual Property:</strong> Users are not permitted to extract or exfiltrate any content from the platform in any form.</li>
                        <li><strong>Service Modifications:</strong> We reserve the right to terminate user access to the website for any reason. Suspensions involve disabling access to the service until a successful appeal.</li>
                        <li><strong>Liability Limitations:</strong> We are not liable for any downtime, data loss, or inaccuracies. Users are advised to back up their data frequently. The platform is for research purposes only and not for medical use.</li>
                        <li><strong>User-Generated Content:</strong> Uploaded scorings, sleep recordings, and medical data are anonymized and may be used in training machine learning models. These models are considered the property of the platform.</li>
                        <li><strong>Termination of Use:</strong> Exfiltration of data or creation of profane/non-scientific content will result in immediate termination or suspension of the user's account.</li>
                        <li><strong>Governing Law:</strong> These terms are governed by the laws of Iceland.</li>
                        <li><strong>Amendments to Terms:</strong> Users will be informed of any changes to these terms via email.</li>
                    </ul>
                    
                <Typography variant="body1" style={{ color: '#333', lineHeight: '1.6' }}>
                    <p>For any inquiries related to these terms, please contact b@spock.is.</p>
                </Typography>
            </Paper>
        </Container>
    );
};

export default TOS;